import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const MarketHome = Loadable({
  loader: () => import('./components/MarketHome'),
  loading: LazyLoading,
});

export const marketHomeRoutes = [
  {
    path: '/market/home',
    component: MarketHome,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];
