export const MarketStatusEnums = {
  DD_NOT_STARTED: 'DD_NOT_STARTED',
  DD_STARTED: 'DD_STARTED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export const MakerProfileShopifyStatusEnums = Object.freeze({
  Draft: 'Draft',
  Submitted: 'Submitted',
  Unsubmitted: 'UnSubmitted',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Resubmitted: 'ReSubmitted',
});
