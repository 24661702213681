import { ApolloClient, InMemoryCache, fromPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { notification } from 'antd';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'js-cookie';

import { getNewToken, logout } from '@/_helpers/auth';
import { AUTH } from '@/constants';

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext((operation, { headers }) => {
  // prevent expired token from being sent to the server for tokenRefresh operation
  const token =
    operation.operationName !== 'tokenRefresh' ? Cookies.get(AUTH.token) : '';
  return {
    headers: {
      ...headers,
      authorization: token ? `jwt ${token}` : '',
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }: any): any => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const code = err?.extensions?.exception?.code;
        if (code === 'SignatureExpired' || code === 'ErrorDecoding') {
          const oldHeaders = operation.getContext().headers;
          return fromPromise(
            getNewToken().catch(() => {
              logout();
              return;
            })
          )
            .filter((value) => Boolean(value))
            .flatMap((token) => {
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `jwt ${token}`,
                },
              });
              return forward(operation);
            });
        }
      }

      if (networkError)
        notification.error({
          message: 'Network Error',
          description: networkError?.message,
          placement: 'bottomLeft',
        });
    }
  }
);

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        clients: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        makerProducts: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  link: authLink.concat(errorLink.concat(uploadLink)),
  cache,
  name: 'MakerPortal',
  version: '1.2',
});

export { client };
