export const ProductSortTypesEnums = Object.freeze({
  'Product title A-Z': 'Product title A-Z',
  'Product title Z-A': 'Product title Z-A',
  'Highest price': 'Highest price',
  'Lowest price': 'Lowest price',
  Newest: 'Newest',
  Oldest: 'Oldest',
});

export const sortProductDirectionEnums = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const sortProductFieldEnums = {
  NAME: 'NAME',
  PRICE: 'PRICE',
  MODIFIED_DATE: 'MODIFIED_DATE',
  CREATED: 'CREATED',
  PUBLICATION_DATE: 'PUBLICATION_DATE',
};

export const sortProductOptions = Object.freeze({
  NAME_ASC: {
    field: sortProductFieldEnums.NAME,
    direction: sortProductDirectionEnums.ASC,
    label: 'Product Title A-Z',
  },
  NAME_DESC: {
    field: sortProductFieldEnums.NAME,
    direction: sortProductDirectionEnums.DESC,
    label: 'Product Title Z-A',
  },
  PRICE_ASC: {
    field: sortProductFieldEnums.PRICE,
    direction: sortProductDirectionEnums.ASC,
    label: 'Highest Price',
  },
  PRICE_DESC: {
    field: sortProductFieldEnums.PRICE,
    direction: sortProductDirectionEnums.DESC,
    label: 'Lowest Price',
  },
  MODIFIED_DATE_ASC: {
    field: sortProductFieldEnums.MODIFIED_DATE,
    direction: sortProductDirectionEnums.ASC,
    label: 'Oldest',
  },
  MODIFIED_DATE_DESC: {
    field: sortProductFieldEnums.MODIFIED_DATE,
    direction: sortProductDirectionEnums.DESC,
    label: 'Newest',
  },
  CREATED_ASC: {
    field: sortProductFieldEnums.CREATED,
    direction: sortProductDirectionEnums.ASC,
    label: 'Oldest',
  },
  CREATED_DESC: {
    field: sortProductFieldEnums.CREATED,
    direction: sortProductDirectionEnums.DESC,
    label: 'Newest',
  },
  PUBLICATION_DATE_ASC: {
    field: sortProductFieldEnums.PUBLICATION_DATE,
    direction: sortProductDirectionEnums.ASC,
    label: 'Oldest',
  },
  PUBLICATION_DATE_DESC: {
    field: sortProductFieldEnums.PUBLICATION_DATE,
    direction: sortProductDirectionEnums.DESC,
    label: 'Newest',
  },
});

export const ApiProductSortTypesEnums = Object.freeze({
  AlphaAsc: 'alpha-asc',
  AlphaDesc: 'alpha-desc',
  CreatedDesc: 'created-desc',
  Created: 'created',
  PriceAsc: 'price-asc',
  PriceDesc: 'price-desc',
  BestSelling: 'best-selling',
});

export const ProductSortTypesCorrespondingApiValuesEnums = Object.freeze({
  'Product title A-Z': ApiProductSortTypesEnums.AlphaAsc,
  'Product title Z-A': ApiProductSortTypesEnums.AlphaDesc,
  'Highest price': ApiProductSortTypesEnums.PriceAsc,
  'Lowest price': ApiProductSortTypesEnums.PriceDesc,
  Newest: ApiProductSortTypesEnums.CreatedDesc,
  Oldest: ApiProductSortTypesEnums.Created,
});

export const ProductSortOptions = Object.keys(ProductSortTypesEnums).map(
  (key) => ({
    label: key,
    value: ProductSortTypesCorrespondingApiValuesEnums[key],
  })
);

export const fileSizes = Object.freeze({
  KB: 1024,
});

export const ProductStatus = Object.freeze({
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
});

export const ProductOptionsEnums = Object.freeze({
  Style: 'Style',
  Scent: 'Scent',
  Color: 'Color',
  Size: 'Size',
  Flavor: 'Flavor',
  Material: 'Material',
});

export const ProductOptions = Object.keys(ProductOptionsEnums).map((key) => ({
  label: key,
  value: ProductOptionsEnums[key],
}));

export const ProductLeadTimeEnums = Object.freeze({
  '0-2 Weeks': 'TWO_WEEKS',
  '2-4 Weeks': 'TWO_TO_FOUR_WEEKS',
  '4-6 Weeks': 'FOUR_TO_SIX_WEEKS',
  'Over 6 weeks': 'OVER_SIX_WEEKS',
});

export const ProductStatusEnums = Object.freeze({
  Draft: 'DRAFT',
  Active: 'ACTIVE',
  Archived: 'ARCHIVED',
  'Pending Approval': 'PENDING_APPROVAL',
  Verified: 'VERIFIED',
  Rejected: 'REJECTED',
  'Needs Review': 'NEEDS_REVIEW',
});

export const ProductStatusOptions = Object.keys(ProductStatusEnums).map(
  (key) => ({
    label: key,
    value: ProductStatusEnums[key],
  })
);
