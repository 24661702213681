import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const PayoutPage = Loadable({
  loader: () => import('./components/index'),
  loading: LazyLoading,
});

export const payoutRoutes = [
  {
    path: '/payouts/',
    component: PayoutPage,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];

export const routes = [...payoutRoutes];
