const MIN_PHONE_NUMBER_DIGITS = 3;
const MAX_PHONE_NUMBER_DIGITS = 18;

export const validateMobileNumberLength = (number = '') => {
  let isShort = false;
  let isLong = false;
  const numberLength = number.length;

  if (numberLength < MIN_PHONE_NUMBER_DIGITS) {
    isShort = true;
  } else if (numberLength > MAX_PHONE_NUMBER_DIGITS) {
    isLong = true;
  }

  return { isShort, isLong };
};

export const getNumberWithThousandsSeparator = (value) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
