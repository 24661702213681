import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { ButtonProps } from 'antd/lib/button';

import IconContainer from '@/_components/Icon/IconContainer';

type ContainerButtonProps = {
  title?: string;
  onClick?: () => void;
  icon?: any;
  htmlType?: 'button' | 'submit' | 'reset';
  danger?: boolean;
  disabled?: boolean;
  type?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default';
  size?: 'large' | 'middle' | 'small';
  isAntdIcon?: boolean;
  style?: any;
  isSecondary?: boolean;
  loading?: boolean;
  [x: string]: any;
};

const ContainerButton: React.FC<ContainerButtonProps> = (props) => {
  const {
    title,
    onClick,
    icon,
    htmlType,
    danger,
    disabled,
    type,
    size,
    isAntdIcon,
    style,
    isSecondary,
    loading,
    ...rest
  } = props;

  return (
    <StyledButton
      className={isSecondary ? 'secondary' : ''}
      type={type}
      icon={icon ? <IconContainer icon={icon} isAntdIcon={isAntdIcon} /> : null}
      onClick={onClick}
      style={{ ...style }}
      htmlType={htmlType}
      danger={danger}
      disabled={disabled}
      size={size}
      loading={loading}
      {...rest}
    >
      {title}
    </StyledButton>
  );
};

const StyledButton = styled(Button)<ButtonProps>`
  font-weight: 400;
  &.secondary {
    border-width: 0px;
    color: #222;
    background-color: #f1f1f1 !important;
    transition: box-shadow linear 40ms;
  }
` as any;

ContainerButton.defaultProps = {
  disabled: false,
  type: 'primary',
  htmlType: 'button',
  danger: false,
  isAntdIcon: false,
  style: {},
  isSecondary: false,
  loading: false,
};

export default ContainerButton;
