import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const folder = process.env.NODE_ENV === 'development' ? '' : '/static';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    ns: 'translation',
    fallbackLng: 'en',
    defaultNS: 'translation',
    debug: false,
    nsSeparator: false,
    keySeparator: false,
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: (lng, ns) => {
        return window.location.origin + `${folder}/locales/${lng}/${ns}.json`;
      },
    },
  });
window.i18n = i18n;
export default i18n;
