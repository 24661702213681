import { useMemo } from 'react';
import { Switch, Router, Route } from 'react-router-dom';
import './App.less';
import '@pbp-group/pbp-design-system/dist/style.css';
import { history } from './_helpers/history';
import useAuth from './_hooks/useAuth';
import { AuthContext } from './_contexts/AuthContext';
import NewNavigationLayout from './_templates/layouts/NavigationLayout';
import { authenticatedNavLessRoutes, otherRoutes } from './routes';
import RoutesWithSubRoutes from './_templates/RoutesWithSubRoutes';
import AppLoading from '@/_components/AppLoading';
import { getToken } from '@/_helpers/auth';
import { initLogger } from '@/_helpers/logger';
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

initLogger();

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (_error, query) => {
      if (query.meta.errorMessage) {
        toast.error(query.meta.errorMessage);
      }
    },
  }),
});

function App() {
  const {
    isAuthenticated,
    setIsAuthenticated,
    setUser,
    isLoading,
    user,
    currentCompany,
    setCurrentCompany,
  } = useAuth();

  const token = getToken();
  const values = useMemo(() => {
    return {
      isAuthenticated,
      isLoading,
      setIsAuthenticated,
      user,
      setUser,
      currentCompany,
      setCurrentCompany,
    };
  }, [
    currentCompany,
    isAuthenticated,
    isLoading,
    setCurrentCompany,
    setIsAuthenticated,
    setUser,
    user,
  ]);

  if (token && isLoading) {
    return <AppLoading />;
  }

  return (
    <Router history={history}>
      <AuthContext.Provider value={values}>
        <QueryClientProvider client={queryClient}>
          <Switch>
            {otherRoutes.map((route) => (
              <RoutesWithSubRoutes
                key={route.path}
                {...route}
                loggedOutOnly={true}
              />
            ))}
            {authenticatedNavLessRoutes.map((route) => (
              <RoutesWithSubRoutes
                key={route.path}
                {...route}
                loggedInOnly={true}
              />
            ))}
            <Route
              name="Home"
              render={(routeProps) => <NewNavigationLayout {...routeProps} />}
            />
          </Switch>
        </QueryClientProvider>
      </AuthContext.Provider>
    </Router>
  );
}

export default App;
