const { ProfilesEnum } = require('@/_enums');

export const getProfileFromRole = (role) => {
  let profile = 'Production User';

  Object.keys(ProfilesEnum).find((_profile) => {
    return ProfilesEnum[_profile].find((_role) => {
      if (_role === role) {
        profile = _profile;
        return _profile;
      }
      return false;
    });
  });
  return profile;
};

export const getAllProfilesFromRoles = (roles = [], set_modified = false) => {
  const _profiles = [];
  const _profilesObj = [];

  roles.forEach((role) => {
    let _profile = getProfileFromRole(role.role);
    if (!_profiles.includes(_profile)) {
      if (set_modified) {
        _profile = {
          name: _profile,
          modified: role.modified,
          by: role.modified_by,
        };
        _profilesObj.push(_profile);
        _profiles.push(_profile.name);
      } else {
        _profiles.push(_profile);
      }
    }
  });

  return set_modified ? _profilesObj : _profiles;
};

export const getProfiles = () => {
  return Object.keys(ProfilesEnum);
};

export const validatePassword = (password = '') => {
  const regularExpression =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?:.*[!@#$%^&*+-/,./()><=_|?\\~[{}])?[A-Za-z0-9!@#$%^&*+-/,./()><=_|?\\~[{}]{6,16}$/;

  if (password.trim().length < 8) {
    return false;
  }

  return regularExpression.test(password);
};
