export const product_categories = [
  {
    name: 'Decor',
    options: [
      'Baskets',
      'Candles + Home Fragrance',
      'Holiday',
      'Objects',
      'Pillows + Throws',
      'Stationery + Desks',
      'Wall Accents + Art',
    ],
  },
  {
    name: 'Table',
    options: [
      'Dinnerware',
      'Drinkware',
      'Flatware',
      'Kitchen',
      'Serveware',
      'Table Linens',
    ],
  },
  {
    name: 'Living',
    options: [
      'Bed + Bath',
      'Furniture',
      'Lighting',
      'Mirrors',
      'Rugs',
      'Window Coverings',
    ],
  },
  {
    name: 'Style',
    options: ['Accessories', 'Apparel', 'Bags'],
  },
  {
    name: 'Jewelry',
    options: ['Jewelry'],
  },
  {
    name: 'Beauty + Wellness',
    options: [
      'Aromatherapy',
      'Bath + Body',
      'Beauty',
      'Vitamins + Supplements',
      'Yoga + Meditation Accessories',
    ],
  },
  {
    name: 'Kids + Baby',
    options: ['Kids + Baby'],
  },
  {
    name: 'Other',
    options: ['Other'],
  },
];

export const production_output = [
  {
    name: '0 - 100',
    value: 'ZERO_TO_HUNDRED',
    label: '0 - 100',
  },
  {
    name: '101 - 500',
    value: 'ONE_HUNDRED_ONE_TO_FIVE_HUNDRED',
    label: '101 - 500',
  },
  {
    name: '501 - 1000',
    value: 'FIVE_HUNDRED_ONE_TO_ONE_THOUSAND',
    label: '501 - 1000',
  },
  {
    name: '1001 - 2000',
    value: 'ONE_THOUSAND_ONE_TO_TWO_THOUSAND',
    label: '1001 - 2000',
  },
  {
    name: '2001 - 5000',
    value: 'TWO_THOUSAND_ONE_TO_FIVE_THOUSAND',
    label: '2001 - 5000',
  },
  {
    name: '5000 and Above',
    value: 'FIVE_THOUSAND_AND_ABOVE',
    label: '5000 and Above',
  },
];
