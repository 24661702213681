export const DocTypeEnums = Object.freeze({
  BOM: 'BOM',
  CONTACT: 'Contact',
  CUSTOMER: 'Customer',
  CUSTOMER_GROUP: 'Customer Group',
  DELIVERY_NOTE: 'Delivery Note',
  FIELDS: 'fields',
  ITEM: 'Item',
  GST_HSN_CODE: 'GST HSN Code',
  SALES_ORDER: 'Sales Order',
  PURCHASE_INVOICE: 'Purchase Invoice',
  SALES_INVOICE: 'Sales Invoice',
  SUPPLIER: 'Supplier',
  UOM: 'UOM',
  WORKSTATION: 'Workstation',
  PRODUCTION_PLAN: 'Production Plan',
  ADDRESS: 'Address',
  WORK_ORDER: 'Work Order',
  PURCHASE_ODER: 'Purchase Order',
  USER: 'User',
  QUALITY_INSPECTION_TEMPLATE: 'Quality Inspection Template',
  Item_Quality_Inspection_Parameter: 'Item Quality Inspection Parameter',
  COMPANY: 'Company',
  ITEM_ATTRIBUTE: 'Item Attribute',
  ITEM_ATTRIBUTE_VALUE: 'Item Attribute Value',
  SALES_TAXES_AND_CHARGES_TEMPLATE: 'Sales Taxes and Charges Template',
  CURRENCY_EXCHANGE: 'Currency Exchange',
  DUE_DILIGENCE: 'Due Diligence',
  ITEM_COMPOSITION: 'Item Composition',
  ITEM_DIMENSION: 'Item Dimension',
  FILE: 'File',
  MAKER_PROFILE: 'maker_profile',
  SHOPIFY: 'shopify',
  PRODUCT_CATEGORY: 'Product Category',
  IMAGE: 'Image',
  PACKING_SLIP: 'Packing Slip',
  PACKING_SLIP_ITEM: 'Packing Slip Item',
  DELIVERY_NOTE_ITEM: 'Delivery Note Item',
  SHIPMENT: 'Shipment',
  SHIPMENT_PARCEL: 'Shipment Parcel',
  SHIPMENT_DELIVERY_NOTE: 'Shipment Delivery Note',
  SHOPIFY_API_KEYS: 'Shopify API Key',
  PAYMENT_ENTRY: 'Payment Entry',
  COMPANY_REGISTRATION: 'Company Registration',
  PRICING_RULE: 'Pricing Rule',
  ITEM_PRICING_RULE: 'Pricing Rule Item Code',
  ITEM_VARIANT_ATTRIBUTE: 'Item Variant Attribute',
});
