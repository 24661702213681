import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const WholesaleWidget = Loadable({
  loader: () => import('./components/WholesaleWidget'),
  loading: LazyLoading,
});

export const wholesaleWidgetRoutes = [
  {
    path: '/marketing/wholesale-widget',
    exact: true,
    props: {
      loggedInOnly: true,
    },
    component: WholesaleWidget,
  },
];
