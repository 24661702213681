export const CustomerGroupEnums = Object.freeze({
  Commercial: 'Commercial',
  Government: 'Government',
  Individual: 'Individual',
  'Non Profit': 'Non Profit',
});

export const CustomerGroupOptions = Object.keys(CustomerGroupEnums).map(
  (key) => ({
    name: CustomerGroupEnums[key],
    value: CustomerGroupEnums[key],
  })
);
export const CustomerGSTCategoryEnums = Object.freeze({
  'Registered Regular': 'Registered Regular',
  'Registered Composition': 'Registered Composition',
  Unregistered: 'Unregistered',
  SEZ: 'SEZ',
  Overseas: 'Overseas',
  Consumer: 'Consumer',
  'Deemed Export': 'Deemed Export',
  'UIN Holders': 'UIN Holders',
});

export const CustomerGSTCategoryOptions = Object.keys(
  CustomerGSTCategoryEnums
).map((key) => ({
  name: CustomerGSTCategoryEnums[key],
  value: CustomerGSTCategoryEnums[key],
}));

export const CustomerExportTypeEnums = Object.freeze({
  'With Payment of Tax': 'With Payment of Tax',
  'Without Payment of Tax': 'Without Payment of Tax',
});

export const CustomerExportTypeOptions = Object.keys(
  CustomerExportTypeEnums
).map((key) => ({
  name: CustomerExportTypeEnums[key],
  value: CustomerExportTypeEnums[key],
}));

export const CustomerKYCStatusesEnums = Object.freeze({
  'Not Yet Checked': 'Not Yet Checked',
  'Needs Review': 'Needs Review',
  Approved: 'Approved',
  Rejected: 'Rejected',
});
