import {
  ShopOutlined,
  HomeOutlined,
  TagOutlined,
  UserOutlined,
  InboxOutlined,
  // CreditCardOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  RiseOutlined,
} from '@ant-design/icons';

export interface NavigationMenu {
  title: string;
  key: string;
  path?: string;
  icon: JSX.Element;
  showExtra?: boolean;
  externalLink?: boolean;
  items?: {
    title: string;
    key: string;
    path?: string;
    icon?: JSX.Element;
    showExtra?: boolean;
    externalLink?: boolean;
  }[];
}

export const navigationMenus: NavigationMenu[] = [
  {
    title: 'Home',
    key: 'Home',
    path: '/',
    icon: <HomeOutlined style={{ fontSize: '20px' }} />,
    showExtra: true,
  },
  {
    title: 'Join PBP Market',
    key: 'Market',
    path: '/market/home',
    icon: <ShopOutlined style={{ fontSize: '20px' }} />,
    showExtra: true,
  },
  {
    title: 'Maker Profile',
    key: 'MakerProfile',
    path: '/market/maker-profile',
    icon: <ShopOutlined style={{ fontSize: '20px' }} />,
    showExtra: true,
  },
  {
    title: 'Products',
    key: 'Products',
    path: '/products?tab=active&page=1',
    icon: <TagOutlined style={{ fontSize: '20px' }} />,
  },
  {
    title: 'Buyers',
    key: 'Buyers',
    path: '/buyers',
    icon: <UserOutlined style={{ fontSize: '20px' }} />,
  },
  {
    title: 'Orders',
    key: 'Orders',
    icon: <InboxOutlined style={{ fontSize: '24px' }} />,
    path: '/orders',
    showExtra: true,
  },

  {
    title: 'Payouts',
    key: 'Payouts',
    path: '/payouts',
    icon: <RiseOutlined style={{ fontSize: '24px' }} />,
    showExtra: true,
  },
  // {
  //   title: 'Payments',
  //   key: 'payments',
  //   icon: <CreditCardOutlined style={{ fontSize: '20px' }} />,
  //   path: '/payments',
  // },
  {
    title: 'Cash Flow',
    key: 'CashFlow',
    icon: <ReconciliationOutlined style={{ fontSize: '20px' }} />,
    path: 'https://www.poweredbypeople.com/pages/pbp-cash-flow',
    showExtra: true,
    externalLink: true,
  },
  {
    title: 'Settings',
    key: 'Settings',
    icon: <SettingOutlined style={{ fontSize: '20px' }} />,
    items: [
      {
        title: 'Users',
        key: 'UserManagement',
        path: '/settings/users',
      },
      {
        title: 'Profile',
        key: 'MyProfile',
        path: '/settings/my-profile',
      },

      {
        title: 'Learning Hub',
        key: 'resources',
        path: '/lh-homepage',
      },
      {
        title: 'PBP Verified Assessment',
        key: 'pbpVerifiedAssessment',
        path: 'https://ee.kobotoolbox.org/x/9QyX29KW',

        externalLink: true,
        showExtra: true,
      },
      {
        title: 'Payment Method',
        key: 'bankingInfo',
        path: 'https://zfrmz.com/jfnMMVPt8pogaoHUvW6v',

        externalLink: true,
        showExtra: true,
      },
      {
        title: 'Shopify Integration',
        key: 'ShopifyIntegration',
        path: '/settings/shopify-integration',
      },
    ],
  },
];
