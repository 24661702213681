import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const MakerProfile = Loadable({
  loader: () => import('./components/MakerProfile'),
  loading: LazyLoading,
});

export const marketMakerProfileRoutes = [
  {
    path: '/market/maker-profile',
    component: MakerProfile,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];
