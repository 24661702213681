import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const Home = Loadable({
  loader: () => import('./../dashboard/home/HomePage'),
  loading: LazyLoading,
});

export const routes = [
  {
    path: '/home',
    component: Home,
    exact: true,
    props: {
      loggedOutOnly: true,
    },
  },

  // {
  //   path: '*',
  //   component: NotFound,
  //   props: {
  //     loggedOutOnly: true,
  //   },
  // },
];
