import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const Payments = Loadable({
  loader: () => import('./components/Payments'),
  loading: LazyLoading,
});

export const paymentsRoutes = [
  {
    path: '/payments',
    component: Payments,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];

export const routes = [...paymentsRoutes];
