import React from 'react';
import { ScaleLoader, GridLoader, PropagateLoader } from 'react-spinners';
import styled from 'styled-components';

import { themeTemplate } from '@/theme';
import { LoadingComponentProps } from 'react-loadable';

interface LazyLoadingProps extends LoadingComponentProps {
  size?: number;
}

type CompanyLoadingProps = {
  size?: number;
};

export const LazyLoading: React.FC<LazyLoadingProps> = ({ size = 20 }) => {
  return (
    <StyledDiv>
      <GridLoader size={size} color={themeTemplate.primaryColor} margin={2} />
    </StyledDiv>
  );
};

export const CompanyLoading: React.FC<CompanyLoadingProps> = ({
  size = 20,
}) => {
  return <PropagateLoader size={size} color={themeTemplate.primaryColor} />;
};

export const UpdateLoading = () => {
  return (
    <UpdateDiv>
      <ScaleLoader radius={50} color={themeTemplate.primaryColor} margin={2} />
    </UpdateDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const UpdateDiv = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4050;
  opacity: 0.5;
  transition: opacity 0.3s;
`;
