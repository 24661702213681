import { statusColors } from '@/theme';
import { CompanyCashAdvanceStatusEnums } from '@/_enums';

export const getCashAdvanceVariables = (t = (value) => value, status) => {
  let cashAdvanceVariables = {
    label: t(CompanyCashAdvanceStatusEnums['Not Yet Checked']),
    color: statusColors.default,
  };

  if (status === CompanyCashAdvanceStatusEnums['Not Yet Checked']) {
    cashAdvanceVariables.label = t(
      CompanyCashAdvanceStatusEnums['Not Yet Checked']
    );
    cashAdvanceVariables.color = statusColors.default;
  } else if (status === CompanyCashAdvanceStatusEnums['Needs Review']) {
    cashAdvanceVariables.label = t(
      CompanyCashAdvanceStatusEnums['Needs Review']
    );
    cashAdvanceVariables.color = statusColors.warning;
  } else if (status === CompanyCashAdvanceStatusEnums.Approved) {
    cashAdvanceVariables.label = t(CompanyCashAdvanceStatusEnums.Approved);
    cashAdvanceVariables.color = statusColors.success;
  } else if (status === CompanyCashAdvanceStatusEnums.Rejected) {
    cashAdvanceVariables.label = t('Declined');
    cashAdvanceVariables.color = statusColors.danger;
  }
  return cashAdvanceVariables;
};

export const bankDefaultStatus = (defaultBankStatus) => {
  const statuses = {
    pending_maker_verification: {
      key: 'pending_maker_verification',
      label: 'Pending ...',
      tooltip: 'Pending Maker Verification',
      color: 'orange',
    },
    pending_microdeposit: {
      key: 'pending_microdeposit',
      label: 'Pending ...',
      tooltip: 'Pending Microdeposit',
      color: 'orange',
    },
    unverified: {
      key: 'unverified',
      label: 'Unverified',
      color: 'red',
    },
    verified: {
      key: 'verified',
      label: 'Verified',
      color: 'green',
    },
    none: {
      key: null,
      label: 'Not added',
      color: 'blue',
    },
  };
  const tag = statuses[defaultBankStatus] ?? statuses.none;
  return tag;
};
