import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerButton } from './Buttons';
import styled from 'styled-components';
import { themeTemplate } from '@/theme';
import { ReactComponent as PBPIcon } from '@/_assets/img/pbp-logo-black.svg';
import { handleErrorBoundaryPage } from '@/_mixpanel';

const ErrorBoundaryFallback = ({ error }) => {
  const { t } = useTranslation();
  useEffect(() => {
    handleErrorBoundaryPage({ error: error });
  }, [error]);

  const onClickReset = () => {
    document.location.href = '/';
  };
  return (
    <ErrorBoundaryContainer
      justify="center"
      align="middle"
      style={{ height: '100%' }}
    >
      <Col span={24}>
        <Row justify="center">
          <InnerDiv>
            <PBPIcon className="icon" viewBox="0 0 100 90" fill="#fff" />
          </InnerDiv>
        </Row>
        <Row justify="center">
          <Typography.Title level={3}>
            {t("Oops! Something went wrong. It's not you, it's us.")}
          </Typography.Title>
        </Row>
        <Row justify="center">
          <Typography.Text level={3}>
            {t(
              'Our team has been notified and will be working to fix this as soon as possible.'
            )}
          </Typography.Text>
        </Row>
        <Row justify="center">
          <ContainerButton
            title={t('Go back to home')}
            type="link"
            onClick={onClickReset}
          />
        </Row>
      </Col>
    </ErrorBoundaryContainer>
  );
};

const ErrorBoundaryContainer = styled(Row)`
  background-color: ${themeTemplate.themeColor};
  height: 100%;
`;

const InnerDiv = styled.div`
  margin-bottom: 30px;
  .icon {
    padding: 15px 15px 15px 22px;
    border-radius: 20px;
    background: #000;
    box-sizing: content-box;
  }
`;

export default ErrorBoundaryFallback;
