import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const Maintenance = Loadable({
  loader: () => import('./components/Maintenance'),
  loading: LazyLoading,
});

export const MaintenanceRoutes = [
  {
    path: '/settings/maintenance',
    exact: true,
    props: {
      loggedInOnly: true,
    },
    component: Maintenance,
  },
];
