import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const initLogger = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
};

export const logException = (error) => {
  let extra = { ...error };
  if (error.response) {
    extra = { ...error.response };
  }
  Sentry.setExtra('error', extra);
  Sentry.captureException(error);
};
