import { DocTypeEnums } from '@/_enums';
import { COMPANY, SPECIAL_DUE_DILIGENCE, SPECIAL_DOC } from '../_helpers/api';
import { MixpanelActions } from './actions';
import { Mixpanel } from './middleware';

const customerURL = `${SPECIAL_DOC}/${DocTypeEnums.CUSTOMER}`;
const productURL = `${SPECIAL_DOC}/${DocTypeEnums.ITEM}`;
const orderURL = `${SPECIAL_DOC}/${DocTypeEnums.SALES_ORDER}`;

export const setMixPanelUserProfile = (currentCompany, user) => {
  if (user?.email) {
    Mixpanel.identify(user.email);
    Mixpanel.people.set({
      $email: user.email,
      $name: user.full_name,
      Company: currentCompany?.name,
      'Company Name': currentCompany?.company_name,
    });
  }
};

const identifyUserOnSignup = (response) => {
  const data = response.data;

  let user = { email: data.email };
  let company = { company_name: data.company_name, name: data.name };

  setMixPanelUserProfile(company, user);
};

export const handleNavigationClicks = (action, additionalInfo) => {
  Mixpanel.track(action, additionalInfo);
};

export const handleWhenUserViewedHomepage = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed Homepage'], additionalInfo);
};
export const handleWhenUserViewedMakerProfilepage = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed Maker Profile Page'], additionalInfo);
};
export const handleWhenUserViewedApplyToMarketPage = (additionalInfo) => {
  Mixpanel.track(
    MixpanelActions['Viewed Apply to Market Page'],
    additionalInfo
  );
};
export const handleWhenUserViewedProductListPage = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed Product List Page'], additionalInfo);
};

export const handleWhenUserViewedAProduct = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed a Product'], additionalInfo);
};
export const handleWhenUserViewedAVariant = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed a Variant'], additionalInfo);
};
export const handleWhenUserViewedCustomersPage = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed Customers Page'], additionalInfo);
};
export const handleWhenUserViewedACustomer = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed a Customer'], additionalInfo);
};
export const handleWhenUserViewedOrders = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed Orders'], additionalInfo);
};
export const handleWhenUserViewedAnOrder = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed an Order'], additionalInfo);
};
export const handleWhenUserViewedPaymentsPage = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed Payments Page'], additionalInfo);
};
export const handleWhenUserViewedUsersPage = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed Users Page'], additionalInfo);
};
export const handleWhenUserViewedProfilePage = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed Profile Page'], additionalInfo);
};
export const handleWhenUserViewedResourcesPage = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Viewed Resources Page'], additionalInfo);
};
export const handleWhenUserViewedShopifyIntegrationPage = (additionalInfo) => {
  Mixpanel.track(
    MixpanelActions['Viewed Shopify Integration Page'],
    additionalInfo
  );
};

export const initializeMixPanel = (response) => {
  if (!process.env.REACT_APP_MIXPANEL_TOKEN) return;
  const { config } = response;

  if (config.method === 'post') {
    handlePostRequests(response);
  } else if (config.method === 'put') {
    handlePutRequests(response);
  }
};

const handlePostRequests = (response) => {
  const { config } = response;
  switch (config.url) {
    case COMPANY:
      identifyUserOnSignup(response);
      handleCompanyCreation();
      break;
    case SPECIAL_DUE_DILIGENCE:
      handleDueDiligenceCompletion();
      break;
    case customerURL:
      handleCustomerCreation();
      break;
    case productURL:
      handleProductCreation();
      break;
    case orderURL:
      handleSalesOrderCreation();
      break;
    default:
      break;
  }
};
const getEditURL = (URL, data) => {
  return data?.name ? `${URL}/${data.name}` : URL;
};
const handlePutRequests = (response) => {
  const { config } = response;
  const requestData = config.data ? JSON.parse(config.data) : null;
  switch (config.url) {
    case getEditURL(SPECIAL_DUE_DILIGENCE, requestData):
      handleDueDiligenceCompletion(requestData);
      break;

    default:
      break;
  }
};

const handleCompanyCreation = () => {
  Mixpanel.track(MixpanelActions['Company Registration']);
};
export const handleDueDiligenceCompletion = (requestData, additionalInfo) => {
  if (requestData && requestData.submission_status) {
    Mixpanel.track(MixpanelActions['Filled Due Diligence'], additionalInfo);
  }
};
const handleProductCreation = () => {
  Mixpanel.track(MixpanelActions['Added a Product']);
};

const handleCustomerCreation = () => {
  Mixpanel.track(MixpanelActions['Added a Customer']);
};

const handleSalesOrderCreation = () => {
  Mixpanel.track(MixpanelActions['Created a Sales Order']);
};
export const handleSignIn = (provider) => {
  Mixpanel.track(MixpanelActions[`Sign in with ${provider}`]);
};
export const handleSignUp = () => {
  Mixpanel.track(MixpanelActions['Sign up']);
};

export const handleLogin = () => {
  Mixpanel.track(MixpanelActions['Sign in']);
};

export const handleMarkOrderAsShipped = (action, additionalInfo) => {
  if (action.label === 'Mark as shipped') {
    Mixpanel.track(MixpanelActions['Marked Order as Shipped'], additionalInfo);
  }
};
export const handleDeclineOrder = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Decline Order'], additionalInfo);
};
export const handleConfirmedOrder = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Confirmed Order'], additionalInfo);
};
export const handleUpdatedVariant = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Updated a Variant'], additionalInfo);
};
export const handlePushProductToMarket = (additionalInfo) => {
  Mixpanel.track(
    MixpanelActions['Published Product to Market'],
    additionalInfo
  );
};
export const handleUpdateProduct = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Updated a Product'], additionalInfo);
};
export const handleCreateProduct = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Created a Product'], additionalInfo);
};
export const handleEditMarkerProfile = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Edited Maker Profile'], additionalInfo);
};
export const handleSubmitMarkerProfile = (additionalInfo) => {
  Mixpanel.track(
    MixpanelActions['Submitted Profile to Market'],
    additionalInfo
  );
};
export const handlePreviewOnlineStore = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Previewed Online Store'], additionalInfo);
};
export const handleSavingDueDiligence = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Saved Due Diligence'], additionalInfo);
};
export const handleStartingDueDiligence = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Started Due Diligence'], additionalInfo);
};
export const handleErrorBoundaryPage = (additionalInfo) => {
  Mixpanel.track(MixpanelActions['Encountered error page'], additionalInfo);
};
