import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const AddNewBuyer = Loadable({
  loader: () => import('./components/AddNewBuyer'),
  loading: LazyLoading,
});
const EditBuyer = Loadable({
  loader: () => import('./components/EditBuyer'),
  loading: LazyLoading,
});
const BuyerList = Loadable({
  loader: () => import('./components/BuyerList'),
  loading: LazyLoading,
});

export const buyersRoutes = [
  {
    path: '/buyers',
    component: BuyerList,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/buyers/:id/edit',
    component: EditBuyer,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/buyers/new',
    component: AddNewBuyer,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];

export const routes = [...buyersRoutes];
