import { routes as auth } from '@/pages/auth';
import { routes as products } from '@/pages/products';
import { routes as sales } from '@/pages/sales';
import { routes as other } from '@/pages/other';
import { routes as settings } from '@/pages/settings';
import { routes as dashboard } from '@/pages/dashboard';
import { routes as market } from '@/pages/market';
import { routes as customers } from '@/pages/customers';
import { routes as payments } from '@/pages/payments';
import { routes as marketing } from '@/pages/marketing';
import { routes as buyers } from '@/pages/buyers';
import { routes as payouts } from '@/pages/payouts';

import { WildCardRoutes } from '@/pages/404pages';
import { NotFoundPage } from '@/pages/404pages';

export const authenticatedRoutes = [
  ...products,
  ...sales,
  ...market,
  ...settings,
  ...dashboard,
  ...customers,
  ...payments,
  ...marketing,
  ...buyers,
  ...payouts,
  ...WildCardRoutes,
];

export const authenticatedNavLessRoutes = [...NotFoundPage];

export const otherRoutes = [...auth, ...other];
