import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const HomePage = Loadable({
  loader: () => import('./home/HomePage'),
  loading: LazyLoading,
});

const Resources = Loadable({
  loader: () => import('./components/LearningUniverse'),
  loading: LazyLoading,
});

const LearningHubModule1 = Loadable({
  loader: () => import('./components/LearningHubModule1'),
  loading: LazyLoading,
});
const LearningHubModule2 = Loadable({
  loader: () => import('./components/LearningHubModule2'),
  loading: LazyLoading,
});
const LearningHubModule3 = Loadable({
  loader: () => import('./components/LearningHubModule3'),
  loading: LazyLoading,
});
const LearningHubModule4 = Loadable({
  loader: () => import('./components/LearningHubModule4'),
  loading: LazyLoading,
});
const LearningHubModule5 = Loadable({
  loader: () => import('./components/LearningHubModule5'),
  loading: LazyLoading,
});
const LearningHubModule6 = Loadable({
  loader: () => import('./components/LearningHubModule6'),
  loading: LazyLoading,
});
const LearningHubModule7 = Loadable({
  loader: () => import('./components/LearningHubModule7'),
  loading: LazyLoading,
});
const LearningHubModule8 = Loadable({
  loader: () => import('./components/LearningHubModule8'),
  loading: LazyLoading,
});

export const routes = [
  {
    path: '/',
    component: HomePage,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },

  {
    path: '/lh-homepage',
    component: Resources,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },

  {
    path: '/lh-module-1',
    component: LearningHubModule1,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/lh-module-2',
    component: LearningHubModule2,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/lh-module-3',
    component: LearningHubModule3,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/lh-module-4',
    component: LearningHubModule4,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/lh-module-5',
    component: LearningHubModule5,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/lh-module-6',
    component: LearningHubModule6,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/lh-module-7',
    component: LearningHubModule7,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/lh-module-8',
    component: LearningHubModule8,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];
