export const SalesOrderStatusEnums = Object.freeze({
  Draft: 'Draft',
  Confirmed: 'Confirmed',
  'Partially Fulfilled': 'Partially Fulfilled',
  Fulfilled: 'Fulfilled',
  Delayed: 'Delayed',
});

export const MarketSalesOrderStatusEnums = Object.freeze({
  'Awaiting Maker Confirmation': 'AWAITING_MAKER_CONFIRMATION',
  'In Progress': 'IN_PROGRESS',
  Declined: 'DECLINED',
  'Awaiting Shipment Confirmation': 'AWAITING_SHIPMENT_CONFIRMATION',
  'Awaiting Shipment': 'AWAITING_SHIPMENT',
  Shipped: 'SHIPPED',
  'Partially Shipped': 'Partially Shipped',
  'Under Review': 'Under Review',
  CLOSED: 'CLOSED',
});

export const orderStatusEnums = Object.freeze({
  'Awaiting Maker Confirmation': 'Awaiting Maker Confirmation',
  'In Progress': 'In Progress',
  Declined: 'Declined',
  'Awaiting Shipment Confirmation': 'Awaiting Shipment Confirmation',
  'Awaiting Shipment': 'Awaiting Shipment',
  Shipped: 'Shipped',
});

export const SalesOrderPaymentStatusEnums = Object.freeze({
  Unpaid: 'Unpaid',
  'Partially Paid': 'Partially Paid',
  Paid: 'Paid',
});

export const SalesOrderCashAdvanceStatusEnums = Object.freeze({
  UnSubmitted: 'UnSubmitted',
  Submitted: 'Submitted',
  'In Review': 'In Review',
  Approved: 'Approved',
  Rejected: 'Rejected',
});

export const SalesOrderStatusOptions = Object.keys(SalesOrderStatusEnums).map(
  (key) => ({
    name: SalesOrderStatusEnums[key],
    value: SalesOrderStatusEnums[key],
  })
);

export const SalesOrderPaymentStatusOptions = Object.keys(
  SalesOrderPaymentStatusEnums
).map((key) => ({
  name: SalesOrderPaymentStatusEnums[key],
  value: SalesOrderPaymentStatusEnums[key],
}));

export const SalesOrderStatuses = Object.freeze({
  AWAITING_MAKER_CONFIRMATION: 'Awaiting Maker Confirmation',
  AWAITING_SHIPMENT_CONFIRMATION: 'Awaiting Shipment Confirmation',
  IN_PROGRESS: 'In Progress',
  AWAITING_SHIPMENT: 'Awaiting Shipment',
  SHIPPED: 'Shipped',
  DECLINED: 'Declined',
  CANCELLED: 'Cancelled',
  CREATED: 'Created',
  CLOSED: 'Closed',
  AWAITING_ADDITIONAL_SHIPMENTS: 'Awaiting Additional Shipments',
  DELIVERED: 'Delivered',
  ON_HOLD: 'On Hold',
});
