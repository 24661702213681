import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const UserLogin = Loadable({
  loader: () => import('./components/Login'),
  loading: LazyLoading,
});

const TransformLogin = Loadable({
  loader: () => import('./components/Login/TransformLoginPage'),
  loading: LazyLoading,
});

const CompanyRegistration = Loadable({
  loader: () =>
    import('./components/AuthCore/Register/CompanyRegistration.compound'),
  loading: LazyLoading,
});

const Register = Loadable({
  loader: () => import('./components/AuthCore/Register/Registration.compound'),
  loading: LazyLoading,
});

const ResetPasswordPage = Loadable({
  loader: () => import('./components/Auth/ResetPassword'),
  loading: LazyLoading,
});

const ChangePasswordPage = Loadable({
  loader: () => import('./components/Auth/ChangePassword'),
  loading: LazyLoading,
});

const RedirectPage = Loadable({
  loader: () => import('./components/RedirectPage'),
  loading: LazyLoading,
});

const RegionalMakerRegistration = Loadable({
  loader: () => import('@/_components/templates/MakerRegistration'),
  loading: LazyLoading,
});

export const routes = [
  {
    path: '/register',
    component: Register,
    props: {
      loggedOutOnly: true,
    },
    exact: true,
  },
  {
    path: '/-/register',
    component: RegionalMakerRegistration,
    exact: true,
    props: {
      loggedOutOnly: true,
    },
  },
  {
    path: '/ke/login',
    component: TransformLogin,
    exact: true,
    props: {
      loggedOutOnly: true,
    },
  },
  {
    path: '/login',
    component: UserLogin,
    props: {
      loggedOutOnly: true,
    },
  },

  {
    path: '/company-setup',
    component: CompanyRegistration,
    props: {
      exact: true,
      loggedInOnly: true,
    },
  },

  {
    path: '/forgot-password',
    component: ResetPasswordPage,
    props: {
      loggedOutOnly: true,
    },
  },
  {
    path: '/change-password',
    component: ChangePasswordPage,
    props: {
      loggedOutOnly: true,
    },
  },
  {
    path: '/logout',
    layout: 'nav-less',
    component: RedirectPage,
    exact: true,
    props: {
      loggedOutOnly: true,
    },
  },
];
