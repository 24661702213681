import { MarketStatusEnums } from '@/_enums';
import { NavigationMenu } from '@/_templates/layouts/Sider/navigationMenus';

export const getFilteredMenusBasedOnMarketStatus = (
  menus: NavigationMenu[] = [],
  publishedStatus: string,
  isTransform?: boolean
) => {
  let results = [...menus];

  if (
    publishedStatus !== MarketStatusEnums.VERIFIED &&
    publishedStatus !== MarketStatusEnums.ACTIVE &&
    results.length
  ) {
    results = results.filter((menu) => menu.key !== 'MakerProfile');
  } else if (
    (publishedStatus === MarketStatusEnums.VERIFIED ||
      publishedStatus === MarketStatusEnums.ACTIVE) &&
    results.length
  ) {
    results = results.filter((menu) => menu.key !== 'Market');
  }

  if (!isTransform) {
    return results;
  }

  const filterMenuItems = (menu: NavigationMenu) => {
    switch (publishedStatus) {
      case MarketStatusEnums.ARCHIVED:
      case MarketStatusEnums.DD_NOT_STARTED:
      case MarketStatusEnums.DD_STARTED:
        if (
          menu.key === 'Home' ||
          menu.key === 'Market' ||
          menu.key === 'Products'
        ) {
          return true;
        }
        if (menu.key === 'Settings' && menu.items) {
          menu.items = menu.items.filter(
            (item) => item.key === 'UserManagement' || item.key === 'MyProfile'
          );
          return menu.items.length > 0;
        }
        return false;

      case MarketStatusEnums.VERIFIED:
      case MarketStatusEnums.ACTIVE:
        if (
          menu.key === 'Buyers' ||
          menu.key === 'Orders' ||
          menu.key === 'Payouts'
        ) {
          return false;
        }
        if (menu.key === 'Settings' && menu.items) {
          menu.items = menu.items.filter(
            (item) =>
              item.key !== 'pbpVerifiedAssessment' &&
              item.key !== 'bankingInfo' &&
              item.key !== 'ShopifyIntegration'
          );
        }
        return true;

      default:
        return false;
    }
  };
  results = results.filter(filterMenuItems);

  return results;
};
