import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const Users = Loadable({
  loader: () => import('./components/Users'),
  loading: LazyLoading,
});
const CreateUser = Loadable({
  loader: () => import('./components/CreateUser'),
  loading: LazyLoading,
});

const UserProfile = Loadable({
  loader: () => import('./components/userProfile'),
  loading: LazyLoading,
});

export const usersRoutes = [
  {
    path: '/settings/users',
    exact: true,
    props: {
      loggedInOnly: true,
    },
    component: Users,
  },
  {
    path: '/settings/users/create',
    exact: true,
    props: {
      loggedInOnly: true,
    },
    component: CreateUser,
  },
  {
    path: '/settings/users/:id/details',
    exact: false,
    props: {
      loggedInOnly: true,
    },
    component: UserProfile,
  },
];
