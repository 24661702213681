import { myProfileRoutes } from './myProfile';
import { usersRoutes } from './users';
import { ShopifyIntegrationRoutes } from './BulkImport';
import { MaintenanceRoutes } from './maintenance';
// import { bulkUploadRoutes } from './bulkUpload';

export const routes = [
  ...usersRoutes,
  ...myProfileRoutes,
  ...ShopifyIntegrationRoutes,
  ...MaintenanceRoutes,
  // ...bulkUploadRoutes,
];
