export const weightUoms = [
  { value: 'Kilogram', label: 'Kilogram (Kg)' },
  { value: 'Gram', label: 'Gram (G)' },
  { value: 'Pound', label: 'Pound (Lb)' },
  { value: 'Ounce', label: 'Ounce (Oz)' },
];

export const lead_time_options = [
  { label: '0-2 Weeks', value: 'TWO_WEEKS' },
  { label: '2-4 Weeks', value: 'TWO_TO_FOUR_WEEKS' },
  { label: '4-6 Weeks', value: 'FOUR_TO_SIX_WEEKS' },
  { label: 'Over 6 weeks', value: 'OVER_SIX_WEEKS' },
];

export const availability_options = [
  { label: 'Ready to Ship', value: 'READY_TO_SHIP' },
  { label: 'Make to Order', value: 'MAKE_TO_ORDER' },
];

export const customization_checks = [
  { label: 'Size', name: 'isSizeCustomizable' },
  { label: 'Color', name: 'isColorCustomizable' },
  { label: 'Labeling', name: 'isLabelCustomizable' },
];
