import Cookies from 'js-cookie';
import { notification } from 'antd';
import { gql } from '@apollo/client';
import { googleLogout } from '@react-oauth/google';

import { history } from './history';
import { client } from '@/graphql/config';
import { validatePassword } from './users';
import { AUTH } from '@/constants';

export const logout = async () => {
  googleLogout();
  Cookies.remove(AUTH.token);
  Cookies.remove(AUTH.refreshToken);
  history.push('/login');
};

export const handleResponse = (error: {
  response: { status: any; data: any };
}) => {
  const { status, data } = error.response;

  switch (true) {
    case status === 400 && data?.traceback === 'CSRFTokenError':
      logout();
      break;
    case status === 401:
      logout();
      break;
    default:
      // Popup notification
      handleExpectedError(data);
      break;
  }
};

export const handleUnexpectedError = () => {
  // Popup notification
  notification.error({
    message: <span style={{ fontWeight: 500 }}>Network Error</span>,
    description: 'The operation could not be completed. Please try again later',
    placement: 'bottomLeft',
  });
};

export const handleRegistrationErrors = ({
  error,
  errorFunc,
  defaultMessage,
}: {
  error: any;
  errorFunc: (content: any) => void;
  defaultMessage: string;
}) => {
  // @ts-ignore
  const data = error?.response?.data || null;
  if (Array.isArray(data)) {
    data.forEach((err) => {
      errorFunc(err);
    });
  } else if (data?.message) {
    errorFunc(data?.message);
  } else if (data?.original_error?.Error?.Message) {
    errorFunc(data?.original_error?.Error?.Message);
  } else {
    errorFunc(defaultMessage);
  }
};
const getErrorDescriptionFromERPArrayErrorMessage = (data: any[]) => {
  const stringifiedMessage = data[0] || null;
  let errorDescription = '';
  if (stringifiedMessage) {
    errorDescription =
      JSON.parse(stringifiedMessage.replace('/', ''))?.message || '';
  }
  return errorDescription;
};

export const handleExpectedError = (data: any) => {
  let errorDescription = 'An unexpected issue has occurred';
  let errorMessage = 'Error';
  if (Array.isArray(data)) {
    errorMessage = 'The following item(s) require your attention';
    errorDescription = (
      <ul style={{ paddingInlineStart: 20, paddingTop: 10 }}>
        {data.map((err) => (
          <li key={err}>{err}</li>
        ))}
      </ul>
    ) as any;
  } else if (data?.message?.message) {
    errorDescription = data.message.message;
  } else if (Array.isArray(data?.message)) {
    errorMessage = data.traceback || errorMessage;
    errorDescription =
      getErrorDescriptionFromERPArrayErrorMessage(data.message) ||
      errorDescription;
  } else if (data?.message) {
    errorDescription = data.message;
  }

  notification.error({
    message: <span style={{ fontWeight: 500 }}>{errorMessage}</span>,
    description: errorDescription,
    placement: 'bottomLeft',
    duration: 5,
  });
};

export const getToken = () => {
  const token = Cookies.get(AUTH.token) || null;
  return token;
};

export const getTokenFromUrlParams = () => {
  const searchParams = new URL(document.location as any).searchParams;
  const token = searchParams?.get('token') || null;
  return token;
};

export const handleValidatePassword = (
  name: string,
  errorMessage: string | undefined,
  message: any,
  datacy: string
) => {
  if (
    (name === 'password' || name === 'new_password') &&
    datacy !== 'login_password'
  ) {
    return [
      () => ({
        required: true,
        validator(_: any, value: string | undefined) {
          if (validatePassword(value)) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(errorMessage));
        },
      }),
    ];
  } else if (name === 'confirm_password') {
    return [
      {
        required: true,
        message: message,
      },
      ({ getFieldValue }: any) => ({
        validator(_: any, value: any) {
          if (
            !value ||
            getFieldValue('password') === value ||
            getFieldValue('new_password') === value
          ) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(errorMessage));
        },
      }),
    ];
  } else if (name === 'password' && datacy === 'login_password') {
    return [
      () => ({
        required: true,
        validator(_: any, value: string | undefined) {
          if (validatePassword(value)) {
            return Promise.resolve();
          }
        },
      }),
    ];
  }
};

export const getNewToken = async () => {
  const refreshToken = Cookies.get(AUTH.refreshToken);
  const response = await client.mutate({
    mutation: gql`
      mutation tokenRefresh($refreshToken: String!) {
        tokenRefresh(refreshToken: $refreshToken) {
          token
          refreshToken
        }
      }
    `,
    variables: {
      refreshToken,
    },
  });
  const token = response?.data?.tokenRefresh?.token;
  // set new tokens
  Cookies.set(AUTH.token, token);
  Cookies.set(AUTH.refreshToken, response?.data?.tokenRefresh?.refreshToken);
  return token;
};
