import { Redirect } from 'react-router-dom';
import PageNotFound from '@/_components/PageNotFound';

export const WildCardRoutes = [
  {
    path: '/*',
    component: () => <Redirect to={'/404'} />,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];

export const NotFoundPage = [
  {
    path: '/404',
    component: () => <PageNotFound />,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];
