import { createContext } from 'react';

export const AuthContext = createContext({
  isAuthenticated: false,
  setCurrentCompany: (_: any) => _,
  setUser: (_: any) => _,
  setIsAuthenticated: (_: boolean) => _,
  currentCompany: {
    id: '',
    slug: '',
    defaultCurrency: '',
    taxId: '',
    howDidYouFindPbp: '',
    productionOutput: '',
    brandName: '',
    zohoVendorId: '',
    publishedStatus: '',
    country: '',
    name: '',
    phoneNumber: '',
    newOrders: 0,
    isTransform: false,
    countryOfProduction: [],
    cash_advance_status: '',
  },
  user: {
    id: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    username: '',
    role: '',
    company: '',
    maker: {
      isPbpVerified: false,
      confirmMakerTerms: false,
    },
    shopifyIntegrated: false,
  },
  isMarketLoading: false,
});
