import { Avatar } from 'antd';
import { ImUsers } from 'react-icons/im';
import { themeTemplate } from '@/theme';
import PropTypes from 'prop-types';
import { CiImageOn } from 'react-icons/ci';
import { HiUser } from 'react-icons/hi';
import { getInitials } from '@/_utils/stringUtils';
import styled from 'styled-components';

const CommonAvatar = (props) => {
  const {
    imageURL,
    size,
    shape,
    name,
    //Switch icon Props
    isCustomer,
    isItem,
    isUser,
    disabled,
  } = props;

  const getIcon = () => {
    if (imageURL || name) return null;
    if (isCustomer) {
      return <ImUsers fill={themeTemplate.primaryColor} />;
    }
    if (isItem) {
      return <CiImageOn fill={themeTemplate.primaryColor} size={30} />;
    }
    if (isUser) {
      return <HiUser fill={themeTemplate.primaryColor} />;
    }
  };

  return (
    <StyledAvatar
      src={imageURL}
      size={size}
      shape={shape}
      icon={getIcon()}
      disabled={disabled}
    >
      {getInitials(name)}
    </StyledAvatar>
  );
};
CommonAvatar.propTypes = {
  imageURL: PropTypes.string,
  name: PropTypes.string,

  isCustomer: PropTypes.bool,
  isUser: PropTypes.bool,
  isItem: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shape: PropTypes.oneOf(['circle', 'square']),
};
CommonAvatar.defaultProps = {
  isCustomer: false,
  isUser: false,
  isItem: false,
  imageURL: null,
  disabled: false,
  name: null,
  size: 'default',
  shape: 'square',
};

const StyledAvatar = styled(Avatar)`
  opacity: ${(props) => (props.disabled ? '0.5' : '')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default CommonAvatar;
