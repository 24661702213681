// @ts-nocheck
import { Badge, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { IoLogOutOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { googleLogout } from '@react-oauth/google';

import { themeTemplate } from '@/theme';
import { AuthContext } from '@/_contexts/AuthContext';
import { getTitleCaseString } from '@/_utils/stringUtils';
import { logout } from '@/_helpers/auth';
import { history } from '@/_helpers/history';
import CommonAvatar from '@/_components/CommonAvatar';
import { ContainerButton } from '@/_components/Buttons';

type UserDisplayNavContentProps = {
  onViewProfileClick: (title: string, url: string) => void;
};

const UserDisplayNavContent: React.FC<UserDisplayNavContentProps> = ({
  onViewProfileClick,
}) => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { t } = useTranslation();
  const { currentCompany, user } = useContext(AuthContext);

  const onAccountClick = () => {
    const url = '/settings/my-profile';
    history.push(url);
    onViewProfileClick('Settings', url);
  };

  const onLogout = () => {
    googleLogout();
    logout();
    setIsLoggingOut(true);
  };
  return (
    <UserLayout>
      <ProfileSection>
        <Badge dot style={{ backgroundColor: '#87d068' }}>
          <CommonAvatar
            size={36}
            imageURL={''}
            name={user?.firstName + ' ' + user?.lastName}
          />
        </Badge>
        <UserDetails>
          <Tooltip
            title={user?.firstName + ' ' + user?.lastName || ''}
            placement="top"
          >
            <div className="full_name_text" onClick={onAccountClick}>
              {currentCompany?.name &&
                getTitleCaseString(
                  user?.firstName + ' ' + user?.lastName || ''
                )}
            </div>
          </Tooltip>
          <Tooltip title={currentCompany?.name} placement="right">
            <MenuDescriptionText>{currentCompany?.name}</MenuDescriptionText>
          </Tooltip>
        </UserDetails>
      </ProfileSection>
      <LogoutSection>
        <Tooltip title={t('Logout')} placement="top">
          {!isLoggingOut ? (
            <IoLogOutOutline
              data-cy="logout"
              size={24}
              fill="black"
              onClick={onLogout}
            />
          ) : (
            <ContainerButton type="text" loading />
          )}
        </Tooltip>
      </LogoutSection>
    </UserLayout>
  );
};

const UserDetails = styled.div`
  margin-left: 15px;
  .full_name_text {
    color: ${themeTemplate.primaryColor};
    font-weight: 500;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
`;
const MenuDescriptionText = styled.div`
  color: #737376;
  font-weight: 400;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const UserLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ProfileSection = styled.div`
  display: flex;
  alig-items: center;
`;
const LogoutSection = styled.div`
  cursor: pointer;
  align-self: center;
`;

export default UserDisplayNavContent;
