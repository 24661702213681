import { statusColors, themeTemplate } from '@/theme';
import { MarketStatusEnums } from '@/_enums';
import {
  // IoCheckmarkDoneCircleSharp,

  IoDocumentText,
} from 'react-icons/io5';
// import { HiClock } from 'react-icons/hi';
// import { MdCancel } from 'react-icons/md';

// const MarketStatusStyle = {
//   [MarketStatusEnums.Draft]: {
//     color: statusColors.default,
//     icon: IoDocumentText,
//   },
//   [MarketStatusEnums.Documentation]: {
//     color: statusColors.default,
//     icon: IoDocumentText,
//   },
//   [MarketStatusEnums.PENDING_APPROVAL]: {
//     color: statusColors.warning,
//     icon: HiClock,
//   },
//   [MarketStatusEnums.REJECTED]: {
//     color: statusColors.danger,
//     icon: MdCancel,
//   },
//   [MarketStatusEnums.VERIFIED]: {
//     color: statusColors.success,
//     icon: IoCheckmarkDoneCircleSharp,
//   },
// };

export const getMarketStatusVariables = (
  t = (value) => value,
  status = MarketStatusEnums.VERIFIED,
  iconSize = 26
) => {
  const extraVariables = { text: '', color: '', icon: '' };

  if (status === MarketStatusEnums.PENDING_APPROVAL) {
    extraVariables.text = t('Pending Approval');
    extraVariables.color = statusColors.warning;
  }

  if (status === MarketStatusEnums.REJECTED) {
    extraVariables.text = t('Rejected');
    extraVariables.color = statusColors.danger;
  }

  if (status === MarketStatusEnums.ACTIVE) {
    extraVariables.text = t('Active');
    extraVariables.color = statusColors.success;
  }

  if (status === MarketStatusEnums.VERIFIED) {
    extraVariables.text = t('Draft');
    extraVariables.color = statusColors.success;
  }

  if (status === MarketStatusEnums.ARCHIVED) {
    extraVariables.text = t('Archived');
    extraVariables.color = themeTemplate.dangerColor;
  }

  if (status === MarketStatusEnums.DD_STARTED) {
    extraVariables.text = t('In Progress');
    extraVariables.color = statusColors.default;
  }

  if (status === MarketStatusEnums.DD_NOT_STARTED) {
    extraVariables.text = t('Apply Now');
    extraVariables.color = statusColors.default;
  }

  const Icon = status === MarketStatusEnums.VERIFIED ? IoDocumentText : null;

  if (Icon) {
    extraVariables.icon = (
      <Icon
        size={iconSize}
        fill={extraVariables.color}
        style={{ verticalAlign: 'middle', marginRight: 5 }}
      />
    );
  }

  return extraVariables;
};

export const getMakerProfileVariables = (t = (value) => value, status) => {
  let makerProfileVariable = {
    label: t('On PBP Market'),
    color: statusColors.default,
  };

  if (status === MarketStatusEnums.VERIFIED) {
    makerProfileVariable.label = t('Draft');
    makerProfileVariable.color = statusColors.success;
  }

  if (status === MarketStatusEnums.ACTIVE) {
    makerProfileVariable.label = t('Active');
    makerProfileVariable.color = statusColors.default;
  }

  if (status === MarketStatusEnums.ARCHIVED) {
    makerProfileVariable.label = t('Archived');
    makerProfileVariable.color = themeTemplate.dangerColor;
  }

  if (status === MarketStatusEnums.REJECTED) {
    makerProfileVariable.label = t('Rejected');
    makerProfileVariable.color = themeTemplate.dangerColor;
  }

  return makerProfileVariable;
};
