export const strippedString = (htmlString) => {
  if (!htmlString) return;
  return htmlString.replace(/(<([^>]+)>)/gi, '');
};

export const capitalizeFirstLetter = (text = '') => {
  if (!text?.charAt(0)) return '';
  let lowerCaseString = text.toLowerCase();
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
};

export const getTitleCaseString = (text = '') => {
  let sentenceCaseString = '';
  text &&
    text.split(' ').forEach((element, index) => {
      sentenceCaseString += index === 0 ? '' : ' ';
      sentenceCaseString += capitalizeFirstLetter(element);
    });
  return sentenceCaseString;
};

export const replaceSingleQuotes = (text) => {
  if (!text) return;
  return text.replace(/´|"|'/g, '');
};

export const stringToArrayList = (text) => {
  if (!text) return;
  return text.split(',');
};

export const extractFirstLetterFromWord = (text) => {
  if (!text) return;
  return text
    .split(' ')
    .map((item) => item.toUpperCase().substring(0, 1))
    .join('');
};

export const splitString = (text, condition, index) => {
  if (!condition && text) return;
  return text.split(condition)[index];
};

export const generateRandomCharacter = () => {
  let text = '';
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (var i = 0; i < 5; i++)
    text = possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const getInitials = (text = '') => {
  let initials = '';
  text &&
    text
      .split(' ')
      .slice(0, 2)
      .forEach((word) => {
        initials += (word || '').charAt(0).toUpperCase();
      });
  return initials;
};

export const convertNumberToWord = (num) => {
  var ones = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];
  var tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  var numString = num.toString();

  if (num < 0) throw new Error('Negative numbers are not supported.');

  if (num === 0) return 'zero';

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    return tens[numString[0]] + ' ' + ones[numString[1]];
  }

  //100 and more
  if (numString.length == 3) {
    if (numString[1] === '0' && numString[2] === '0')
      return ones[numString[0]] + ' hundred';
    else
      return (
        ones[numString[0]] +
        ' hundred and ' +
        convertNumberToWord(+(numString[1] + numString[2]))
      );
  }

  if (numString.length === 4) {
    var end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[numString[0]] + ' thousand';
    if (end < 100)
      return ones[numString[0]] + ' thousand and ' + convertNumberToWord(end);
    return ones[numString[0]] + ' thousand ' + convertNumberToWord(end);
  }
};
