import Loadable from 'react-loadable';

import { LazyLoading } from '@/_components/Loading';

const ProductListPage = Loadable({
  loader: () => import('./components/ProductListPage'),
  loading: LazyLoading,
});

const CreateItem = Loadable({
  loader: () => import('./components/CreateProduct'),
  loading: LazyLoading,
});

const EditItem = Loadable({
  loader: () => import('./components/EditProduct'),
  loading: LazyLoading,
});

const ShopifyProductsImportSuccessPG = Loadable({
  loader: () => import('./components/ShopifyProductsImportResult'),
  loading: LazyLoading,
});
export const productRoutes = [
  {
    path: '/products/create',
    component: CreateItem,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/products/:id/edit',
    component: EditItem,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },

  {
    path: '/products',
    component: ProductListPage,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/shopify/products/result',
    component: ShopifyProductsImportSuccessPG,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];
