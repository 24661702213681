export const REGISTER_USER = 'register_user_cognito_produce';
export const CONFIRM_SIGNUP = 'confirm_sign_up';
export const DOC = 'doc';
export const USER = 'user';
export const ASSIGN_ROLES = 'assign_roles';
export const COMPANY = 'company';
export const UPLOAD_IMAGE = 's3_image_upload ';
export const CREATE_ITEM_VARIANT = 'item_variant';
export const SALES_ORDERS = 'Sales Order';
export const MARKET_SALES_ORDER = 'update_order_status';
export const EDIT_DOC = 'edit';
export const SPECIAL_DOC = 'docs';
export const EXCHANGE_RATE = 'exchange_rates';
export const SPECIAL_DUE_DILIGENCE = 'due-diligence';
export const UPLOAD_FILE = 'file_upload';
export const UPLOAD_FILE_ATTACHMENT = 'upload_doc_attachments';
export const DOCUMENT = 'document';
export const CASH_ADVANCE_REQUEST = 'cash_advance_request';
export const UPLOAD_BULK_IMAGE = 'upload_to_image_doc';
export const CUSTOMER = 'customer';
export const GET_PDF = 'get_pdf';
export const SHOPIFY_INTEGRATION = 'shopify/products';
export const VERIFY_SHOPIFY_API_KEYS = 'shopify/verify_keys';
export const COMPANY_REGISTRATION = 'company_registration';
export const GOOGLE_COMPANY_REGISTRATION = 'oauth_company_registration';
export const MAKER_NOT_FIT_COMPANY_REGISTRATION = 'register_unfit_maker';
export const CHANGE_USER_PASSWORD = 'change_cognito_user_password';
export const RESET_USER_PASSWORD = 'send_password_reset_email';
export const LOGIN = 'user_sign_in';
export const GET_OAUTH_URLS = 'oauth';
export const ZOHO_PAYOUTS_ADVANCES = 'zoho_advances_payouts';
export const BULK_UPDATE = 'bulk_update';
export const CREATE_BULK_DOCS = 'bulk_create';
export const UPLOAD_CSV_FILE = 'import_product_csv';
export const DELETE = 'delete';
export const SINGLE = 'single';
export const FINTECH_AUTH = 'oauth2/token';
export const MODERN_TREASURY = 'modern-treasury/api';
export const FINTECH_PAYMENT_URL = 'payment-url';
export const FINTECH_MAKER_DETAILS = 'maker-accounts-detail';
export const SHOPIFY = 'shopify';
export const BULK_PUSH_PRODUCT = 'bulk/product';
export const CHANGE_LOGGED_IN_USER_PASSWORD = 'account/change/password';
export const CLAIM_MAKER_ACCOUNT = 'claim/account/reset/password';
