import React from 'react';
import { Result, Button, Row, Col } from 'antd';
import { useHistory } from 'react-router';

const PageNotFound = () => {
  const history = useHistory();

  const navigateToHomePage = () => {
    history.push('/');
  };

  return (
    <Row justify="center" style={{ marginTop: '50px' }}>
      <Col>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" onClick={navigateToHomePage}>
              Back Home
            </Button>
          }
        />
      </Col>
    </Row>
  );
};

export default PageNotFound;
