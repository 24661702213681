export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const HOME = '/';
export const STOCK = 'get_stock_projected_qty';
export const GET_SINGLE_ITEM = 'get_single_item';
export const SIGN_MEDIA_URL = 'sign_media_url';
export const UPLOAD_DOC_ATTACHMENTS = 'upload_doc_attachments';
export const UPDATE = 'edit';
export const DESK_FILE_UPLOAD =
  '/method/pbp_erp_app.s3_uploader.file_upload.upload_file';
export const GET_BULK_DOC_DETAILS = 'docs_full_details';
export const ORDER_FULFILLMENT = 'order_fulfillment';
export const COMPANY_SETUP = '/company-setup';
export const MAKER_NOT_FIT = '/maker-not-fit';
