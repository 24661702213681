export const RoleList = [
  'Supplier',
  'Manufacturing User',
  'Sales User',
  'Stock User',
  'Item Manager',
  'Purchase User',
  'Projects User',
  'Sales Manager',
  'Stock Manager',
  'System Manager',
  'Projects Manager',
  'Purchase Manager',
  'Manufacturing User',
  'Sales Master Manager',
  'Manufacturing Manager',
  'Purchase Master Manager',
  'Manufacturing User',
  'Purchase user',
  'Item Manager',
  'Sales User',
];

export const ProfilesEnum = Object.freeze({
  'Production User': ['Manufacturing User'],
  'Production Manager': ['Purchase user', 'Item Manager', 'Sales User'],
  Admin: [
    'Stock User',
    'Projects User',
    'Sales Manager',
    'Stock Manager',
    'System Manager',
    'Projects Manager',
    'Purchase Manager',
    'Sales Master Manager',
    'Manufacturing Manager',
    'Purchase Master Manager',
  ],
});

export const RolesEnum = {
  Admin: 'MAKER_ADMIN',
  Staff: 'MAKER_USER',
};

export const RolesOptions = Object.keys(RolesEnum).map((key) => ({
  label: key,
  value: RolesEnum[key],
}));
