import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const ShopifyIntegration = Loadable({
  loader: () => import('./components/ShopifyIntegration'),
  loading: LazyLoading,
});

export const ShopifyIntegrationRoutes = [
  {
    path: '/settings/shopify-integration',
    exact: true,
    props: {
      loggedInOnly: true,
    },
    component: ShopifyIntegration,
  },
];
