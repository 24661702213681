import { Card, Form, Col, Row, Tag } from 'antd';
import styled, { css } from 'styled-components';
import { themeTemplate } from '@/theme';

export const PageContainer = styled(Row)`
  max-width: 1400px;
  margin: 0 auto;
`;
export const ContentDiv = styled.div`
  margin: 0px 32px 20px 32px;
  @media only screen and (max-width: 576px) {
    margin: 0px;
  }
  border-radius: 8px;
`;

export const ContentCard = styled(Card)`
  margin: 0px 26px 0px 26px;
  @media only screen and (max-width: 576px) {
    margin: 0px 20px 0px 20px;
  }
`;

export const NewSectionCard = styled(Card)`
  // padding: 1.05rem;
  box-shadow: 0 1px 3px 0 rgb(139 139 139 / 27%);
  border-radius: 0.5rem;
  margin-bottom: 16px;
  .ant-card-head-title {
    font-weight: 600;
    line-height: 1.5rem;
    font-size: 1rem;
  }
  .ant-form-item-label > label .ant-form-item-tooltip title {
    color: #000 !important;
  }
  .ant-form-item-label label,
  .ant-card-head-title .header__subtitle {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    font-weight: 400 !important;
    color: #202223 !important;
  }
  .header__subtitle a {
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    line-height: 1.25rem;
    font-weight: 400 !important;
  }
  ant-card-head-title svg {
    color: #202223 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    font-weight: 400 !important;
  }
`;

export const SectionCard = styled(Card)`
  padding-bottom: 15px;
  box-shadow: 0 1px 3px 0 rgb(139 139 139 / 27%);
  border-radius: 6px;
  margin-bottom: 15px;
  .card--title {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
  .card--subtitle {
    font-size: 0.8125rem;
    font-weight: normal;
  }
  .card--link {
    font-size: 0.8125rem;
    font-weight: normal;
    color: blue;
    cursor: pointer;
    color: #006621;
  }
`;

export const EmptyContentCard = styled(Card)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 20px;
  min-height: ${(props) => props.minHeight || 0}px;
`;

export const GlobalFormStyles = css`
  .ant-input-number,
  .ant-select-selector,
  .ant-input-affix-wrapper,
  .ant-input-group-addon,
  .ant-select-selection-search,
  .ant-picker {
    // background: #f1f1f1 !important;
  }
  .ant-select-multiple .ant-select-selection-item {
    padding: 15px 10px;
    color: #000;
    height: 20px;
    background-color: #f1f1f1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ant-input {
    // background: #f1f1f1 !important;
    font-size: ${(props) => props.fontsizeinput}px;
  }
  .ant-form-item-label label {
    font-size: ${(props) => props.fontsizelabel}px;
  }
  .ant-input[disabled] {
    color: #222 !important;
    font-weight: 600 !important;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      color: #222 !important;
      font-weight: 600 !important;
    }
    .ant-select-selection-item {
      // background: ${themeTemplate.themeColor} !important;
    }
  }
`;
export const StyledForm = styled(Form)`
  .ant-form-item-label label {
    font-size: 14px;
  }

  ${GlobalFormStyles}
  .ant-form-item {
    margin-bottom: ${(props) => props.marginbottom || '0'}px !important;
  }
`;

export const BorderedCol = styled(Col)`
  border-radius: 5px;
  border: 0.5px solid #f0f0f0;
  padding: 20px;
  margin: 10px;
  height: fit-content;
`;

export const StyledRowHeaderWrapper = styled(Row)`
  display: flex;
  width: 87.9%;
  position: sticky;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  z-index: 1000;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledTag = styled(Tag)`
  border-radius: 0.125rem !important;
  font-size: 0.75rem !important;
  text-transform: uppercase;
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
  vertical-align: middle;
  border: none !important;
  font-weight: 700 !important;
  box-shadow: none !important;
`;

export const MainDiv = styled.div`
  background: ${themeTemplate.themeColor};
  min-height: 100%;
  @media only screen and (max-width: 992px) {
  }
`;

export const ContentDivWrapper = styled.div`
  width: 65%;
  margin: 0 auto;
  @media only screen and (max-width: 576px) {
    margin: 0 auto;
  }
  border-radius: 8px;
`;
