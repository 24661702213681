export const validURL = new RegExp(
  /^[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/
);

export const checkForDecimal = new RegExp('/^(d+(.d+)?)$/');

export const specialCharacters = /^[a-zA-Z0-9$\-_.+!*'(),]*$/g;

export const passwordCharacters =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?:.*[!@#$%^&*+-/,./()><=_|?\\~[{}])?[A-Za-z0-9!@#$%^&*+-/,./()><=_|?\\~[{}]{8,20}$/;

export const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const decimalWithTwoDecimalPlaces = /^(\d+)\.{0,1}(\d{1,2})?$/;

export const numberOnlyInInput = /^[0-9\b]+$/;
