import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { history } from '@/_helpers/history';
import { HOME } from '@/_helpers/urls';
import { getToken } from '@/_helpers/auth';
import { getAppendedUrl } from '@/_helpers/helpers';
import { AUTH } from '@/constants';

import { useMeLazyQuery, MeQueryResult } from '@/generated/graphql';

type User = {
  company: string | null | undefined;
  country: string | null | undefined;
};

const useAuth = () => {
  const [getMe] = useMeLazyQuery();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [currentCompany, setCurrentCompany] = useState({});

  useEffect(() => {
    initAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initAuth = () => {
    const token = getToken();

    if (token) {
      Cookies.set(AUTH.token, token);
      fetchUserInfo();
    } else {
      endLoading();
    }
  };

  const endLoading = () => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  const clearLocalStorageRedirect = () => {
    localStorage.removeItem('redirectURL');
  };

  const getRedirectUrl = () => {
    const localStorageRedirect = localStorage.getItem('redirectURL');
    const currentUrl = getAppendedUrl(history.location);

    if (localStorageRedirect === '/logout') {
      clearLocalStorageRedirect();
      return HOME;
    } else if (currentUrl === '/' && localStorageRedirect) {
      clearLocalStorageRedirect();
      return localStorageRedirect;
    } else if (currentUrl !== '/') {
      return currentUrl;
    } else {
      return HOME;
    }
  };

  const fetchUserInfo = async () => {
    try {
      setLoading(true);
      const response: MeQueryResult = await getMe();

      const currentUser = {
        ...response?.data?.me,
        company: response?.data?.me?.maker?.name,
        country: response?.data?.me?.maker?.country,
      };

      setUser(currentUser || null);

      setCurrentCompany(() => ({
        ...response?.data?.me?.maker,
        defaultCurrency: response?.data?.me?.maker?.defaultCurrency
          ? response?.data?.me?.maker?.defaultCurrency
          : 'USD',
      }));

      setIsAuthenticated(true);
      setLoading(false);
      const redirectURL = getRedirectUrl();
      history.push(redirectURL);
    } catch (error) {
      setLoading(false);
    }
  };
  return {
    isAuthenticated,
    setIsAuthenticated,
    isLoading,
    setLoading,
    user,
    setUser,
    currentCompany,
    setCurrentCompany,
  };
};

export default useAuth;
