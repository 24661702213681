import React from 'react';
import { themeTemplate } from '@/theme';
import SquareLoader from 'react-spinners/SquareLoader';
import { ReactComponent as PBPIcon } from '../_assets/img/pbp-logo-black.svg';
import styled from 'styled-components';

type AppLoadingProps = {
  showLoader?: boolean;
  title?: string;
};

const AppLoading: React.FC<AppLoadingProps> = ({ showLoader, title }) => {
  return (
    <AppLoader>
      <InnerDiv>
        <div className="flex items-center justify-center">
          <PBPIcon className="icon" viewBox="0 0 100 90" fill="#fff" />
        </div>
        <p>{title}</p>

        {showLoader && (
          <div role="status">
            <SquareLoader size={30} color={themeTemplate.primaryColor} />
          </div>
        )}
      </InnerDiv>
    </AppLoader>
  );
};

AppLoading.defaultProps = {
  showLoader: true,
  title: 'Portal | Powered By People 1.0',
};
const AppLoader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${themeTemplate.themeColor};
  color: black;
  font-size: large;
  font-weight: 700;
  text-align: center;
`;

const InnerDiv = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .icon {
    padding: 15px 15px 15px 22px;
    border-radius: 20px;
    background: #000;
    box-sizing: content-box;
    margin-bottom: 15px;
  }
`;
export default AppLoading;
