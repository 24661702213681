export const CompanyCashAdvanceStatusEnums = Object.freeze({
  'Not Yet Checked': 'Not Yet Checked',
  'Needs Review': 'Needs Review',
  Approved: 'Approved',
  Rejected: 'Rejected',
});

export const CompanyRegistrationStatus = Object.freeze({
  Pending: 'Pending',
  Completed: 'Completed',
  Draft: 'Draft',
  Loading: 'Loading',
  Error: 'Error',
});

export const DefaultCompanyCurrency = Object.freeze({
  Currency: 'USD',
});
