export const SideMenuKeysEnums = Object.freeze({
  Home: 'Home',
  GettingStarted: 'GettingStarted',
  Market: 'Market',
  MakerProfile: 'MakerProfile',
  Products: 'Products',
  Customers: 'Customers',
  Orders: 'Orders',
  payments: 'payments',
  CashFlow: 'CashFlow',
  UserManagement: 'UserManagement',
  MyProfile: 'MyProfile',
  'product-attributes': 'product-attributes',
  resources: 'resources',
  PaymentMethod: 'PaymentMethod',
  ShopifyIntegration: 'ShopifyIntegration',
});
