import moment from 'moment';
import numeral from 'numeral';
import { isEqual, orderBy } from 'lodash';
import { DocTypeEnums } from '@/_enums';
import { ApiProductSortTypesEnums, fileSizes } from '@/_enums/items';
import { validateMobileNumberLength } from '@/_utils/numberUtils';
import { FieldLimitValueEnums } from '@/_enums/fieldsLimitValues';
import {
  passwordCharacters,
  specialCharacters,
  validEmail,
  validURL,
} from './regex.js';
import { transform } from '@/_helpers/images';
import { Checkbox, Select, Row, Col, Form, notification } from 'antd';
import { SideMenuKeysEnums } from '@/_enums/menu.js';
import { handleNavigationClicks } from '@/_mixpanel/index.js';
import { MixpanelActions } from '@/_mixpanel/actions.js';
import { lead_time_options } from '@/pages/products/items/components/_partials/fieldsOptions.js';
import { product_categories } from './product_categories.js';
import { newGenerateVariants } from './items.js';

export const defaultPage = { page: 1, pageSize: 20 };

export const getPageStartAndLength = (page, pageSize) => {
  let positiveValue = parseInt((page - 1) * pageSize);
  let limit_start = Math.abs(positiveValue);
  return { limit_start, page_limit: pageSize };
};

export const getCurrentPage = (limit_start, page_limit) => {
  return parseInt(limit_start) === 0
    ? 1
    : parseInt(limit_start / page_limit) + 1;
};

export const getCountField = () => {
  return '["count(name) AS total_elements"]';
};
export const getDocField = (doctype, field) => {
  return `["${field}"]`;
};
export const getTotalElements = (data) => {
  return (data || [])[0]?.total_elements || 0;
};
export const getDocFields = (doctype, fields = []) => {
  let filters = '';

  for (let field in fields) {
    let _filters =
      filters + `"${'`'}tab${doctype}${'`'}.${'`'}${fields[field]}${'`'}"`;
    _filters =
      parseInt(field) !== fields.length - 1 ? _filters + ',' : _filters;

    filters = _filters;
  }

  return `[${filters}]`;
};

export const showNotStartedYetCard = ({
  searchParams = {},
  loading = false,
  data = [],
  filtersToIgnore = [],
}) => {
  const params = { ...searchParams };
  const page = params?.page || 1;
  let filtersAvailable = false;

  // Remove filters to ignore
  delete params.pageSize;
  delete params.page;
  delete params.order_by;

  filtersToIgnore.forEach((filter) => {
    delete params[filter];
  });

  Object.values(params).every((value) => {
    if (value) {
      filtersAvailable = true;
      return false;
    }
    return true;
  });

  return page === 1 && !filtersAvailable && !loading && data.length === 0;
};

export const getYearsRange = (current = moment().year(), end = 1900) => {
  const years = [];
  for (let i = current; i >= end; i--) {
    years.push(i);
  }
  return years;
};

export const getImageFileURL = (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => res(e.target.result);
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });
};

export const onPreview = async (file) => {
  let src = file.url;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow.document.write(image.outerHTML);
};

export const getAddressDataFromFormList = ({
  address,
  linkName = null,
  linkDoctype = null,
  name = null,
}) => {
  return address.map((adds) => {
    let newAddress = {
      address_line1: adds.address_line1,
      address_line2: adds.address_line2,
      address_type: adds.address_type,
      city: adds.city,
      country: adds.country,
      pincode: adds.pincode,
      state: adds.state,
      gst_state: adds.gst_state,
      gstin: adds.gstin,
      doctype: DocTypeEnums.ADDRESS,
      docstatus: 0,
    };
    if (linkName && linkDoctype) {
      newAddress['links'] = [
        {
          link_name: linkName,
          link_doctype: linkDoctype,
          docstatus: 1,
        },
      ];
    }
    if (name) {
      newAddress['name'] = name;
    }
    return newAddress;
  });
};

export const getItemListDataFromFormList = (
  itemList,
  extra = {},
  isPayload = true
) => {
  return itemList.map((item) => {
    const itemObject = item.item;
    let newItem = {
      item_name: itemObject?.item_name,
      item_code: itemObject?.item_code,
      description: itemObject?.description,
      qty: item.qty,
      rate: item.rate,
      amount: item.amount,
      ...extra,
    };
    if (!isPayload) {
      newItem.item = itemObject;
    }

    return newItem;
  });
};

export const getTaxListDataFromFormList = (taxList = []) => {
  return taxList.map((tax) => {
    let newItem = {
      ...tax,
      account_head: tax.account_head,
      charge_type: tax.charge_type,
      rate: Number(tax.rate),
      tax_amount: Number(tax.amount),
    };

    return newItem;
  });
};

export const getPrimaryContact = ({
  contact,
  linkName = null,
  linkDoctype = null,
  name = null,
}) => {
  let newContact = {
    first_name: contact.first_name,
    last_name: contact.last_name,
    is_primary_contact: 1,
    email_ids: [{ email_id: contact.email_id, is_primary: 1 }],
    phone_nos: [
      {
        phone: contact.phone,
        is_primary_mobile_no: 1,
        is_primary_phone: 1,
      },
    ],
  };

  if (name) {
    newContact['name'] = name;
  }

  if (linkName && linkDoctype) {
    newContact['links'] = [
      {
        link_name: linkName,
        link_doctype: linkDoctype,
        docstatus: 1,
      },
    ];
  }
  return newContact;
};

export const extractBillingAndShippingAddress = (addresses) => {
  const billingAddress =
    addresses.find((address) => address.address_type === 'Billing') || {};
  const shippingAddress =
    addresses.find((address) => address.address_type === 'Shipping') || {};
  return {
    billingAddress,
    shippingAddress,
  };
};
export const phoneNumberValidator = (_, value) => {
  const { isShort, isLong } = validateMobileNumberLength(value?.phone);
  const numberPattern = /^\d*$/;
  if (value?.phone && !numberPattern.test(value?.phone)) {
    return Promise.reject(new Error('Only numbers are allowed.'));
  }
  if ((!isShort && !isLong) || !value?.phone) {
    return Promise.resolve();
  }

  return Promise.reject(new Error('Please enter a valid phone number!'));
};

export const phoneNumberValidatorInput = (_, value) => {
  const { isShort, isLong } = validateMobileNumberLength(value);
  const mobileRegex = /^\+\d+$/;
  if (!isShort && !isLong && mobileRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Please enter a valid phone number!'));
};

export const getBulkEditActions = (items = []) => {
  const result = {
    archived: false,
    status: 'DRAFT',
  };

  items.forEach((item) => {
    if (item?.status !== 'DRAFT') {
      result.status = 'PENDING_APPROVAL';
    }
    if (item?.status === 'ARCHIVED') {
      result.archived = true;
    }
  });

  return result;
};

export function renameFile(originalFile) {
  const newName = originalFile.name.replace(/\s+/g, '-').toLowerCase();
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export const getAppendedUrl = (location, ignoreQueryString = false) => {
  const { pathname, search } = location;
  let path = pathname;
  if (!ignoreQueryString && search) {
    path += search;
  }
  return path;
};

const formatDateForFilter = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatDateRange = (range) => {
  let dateRange = null;
  if (range) {
    dateRange = [formatDateForFilter(range[0]), formatDateForFilter(range[1])];
  }
  return dateRange;
};

export const handleCheckBoxGroupWithNoneValue = ({
  form,
  key,
  params = {
    prevValues: [],
    currentValues: [],
  },
  noneValue = 'None',
  setPreviousValues,
}) => {
  const prevValues = [...params.prevValues];
  const currentValues = [...params.currentValues];
  let isNoneSelected = false;
  currentValues.forEach((el) => {
    if (el === noneValue) {
      isNoneSelected = !prevValues.find((val) => val === el);
    }
  });

  if (isNoneSelected) {
    form.setFieldsValue({ [key]: [noneValue] });
    setPreviousValues([noneValue]);
  } else {
    let newCurrentValues = currentValues.filter((val) => val !== noneValue);
    form.setFieldsValue({ [key]: [...newCurrentValues] });
    setPreviousValues([...newCurrentValues]);
  }
};

export const removeFunctionsFromOject = (obj) => {
  const stringified = JSON.stringify(obj);
  const parsed = JSON.parse(stringified);
  return parsed;
};

export const parseArrayStringField = (form, fieldName) => {
  return {
    [fieldName]:
      typeof form.getFieldValue(fieldName) === 'string'
        ? JSON.parse(form.getFieldValue(fieldName))
        : form.getFieldValue(fieldName) || [],
  };
};

export const getIsObjectValuesEqual = (object1, object2) => {
  let isEqual = true;
  let keys =
    Object.keys(object1).length > Object.keys(object2).length
      ? Object.keys(object1)
      : Object.keys(object2);
  for (let key of keys) {
    let value1 = object1[key];
    let value2 = object2[key];
    !value1 && value1 !== false && (value1 = '');
    !value2 && value2 !== false && (value2 = '');
    if (value1 !== value2) {
      isEqual = false;
    }
  }
  return isEqual;
};

export const getSortOptions = ({ t }) => [
  { value: 'Newest', title: t('Newest') },
  { value: 'Oldest', title: t('Oldest') },
  { value: 'A-Z', title: t('A-Z') },
  { value: 'Z-A', title: t('Z-A') },
];

export const sortBy = (sort_by) => {
  const sortingOptions = {
    Newest: {
      direction: 'DESC',
      field: 'CREATED_TIME',
    },
    Oldest: {
      direction: 'ASC',
      field: 'CREATED_TIME',
    },
    'A-Z': {
      direction: 'ASC',
      field: 'NAME',
    },
    'Z-A': {
      direction: 'DESC',
      field: 'NAME',
    },
  };
  return sortingOptions[sort_by];
};

export const urlValidator = (_, value) => {
  if (!value || validURL.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Please enter a valid website'));
};

export const shopifyUrlValidator = (_, value) => {
  if (!value || validURL.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error(
      'Please enter a valid shopify URL (e.g https://marie-kamau.myshopify.com/)'
    )
  );
};

export const urlRequiredValidator = (_, value) => {
  if (!value || value.length === 0) {
    return Promise.reject(new Error('This field is required!'));
  }

  if (!validURL.test(value)) {
    return Promise.reject(
      new Error('Please enter a valid website URL e.g example.com')
    );
  }

  return Promise.resolve();
};
export const numberInputValidator = (_, value) => {
  if (!value || value <= FieldLimitValueEnums['Input Number']) {
    return Promise.resolve();
  }
  if (!value || value > FieldLimitValueEnums['Input Number']) {
    return Promise.reject(
      new Error(
        `The value should not be greater than ${numeral(
          FieldLimitValueEnums['Input Number']
        ).format('0,0')}`
      )
    );
  }
};
export const numberRequiredInputValidator = (_, value) => {
  if (!value || value.length === 0) {
    return Promise.reject(new Error('This field is required!'));
  }

  if (!value || value <= FieldLimitValueEnums['Input Number']) {
    return Promise.resolve();
  }
  if (!value || value > FieldLimitValueEnums['Input Number']) {
    return Promise.reject(
      new Error(
        `The number should not be greater than ${numeral(
          FieldLimitValueEnums['Input Number']
        ).format('0,0')}`
      )
    );
  }
};

export const shortTextRequiredInputValidator = (_, value) => {
  if (!value || value.length === 0) {
    return Promise.reject(new Error('This field is required!'));
  }

  if (
    !value ||
    value.length <= FieldLimitValueEnums['Input Short Text Characters']
  ) {
    return Promise.resolve();
  }

  if (
    !value ||
    value.length > FieldLimitValueEnums['Input Short Text Characters']
  ) {
    return Promise.reject(
      new Error(
        `Maximum allowed number of characters is ${FieldLimitValueEnums['Input Short Text Characters']}`
      )
    );
  }
};

export const shortTextInputValidator = (_, value) => {
  if (
    !value ||
    value.length <= FieldLimitValueEnums['Input Short Text Characters']
  ) {
    return Promise.resolve();
  }

  if (
    !value ||
    value.length > FieldLimitValueEnums['Input Short Text Characters']
  ) {
    return Promise.reject(
      new Error(
        `Maximum allowed number of characters is ${FieldLimitValueEnums['Input Short Text Characters']}`
      )
    );
  }
};
export const peopleNumberInputValidator = (_, value) => {
  if (!value || value <= FieldLimitValueEnums['Input People']) {
    return Promise.resolve();
  }

  if (!value || value > FieldLimitValueEnums['Input People']) {
    return Promise.reject(
      new Error(
        `The value should not be greater than ${numeral(
          FieldLimitValueEnums['Input People']
        ).format('0,0')}`
      )
    );
  }
};

export const getAllocatedProductCategories = (
  productCategories,
  productCategoriesMatcher
) => {
  let extractedProductCategories = {
    Decor: [],
    Style: [],
    Rugs: [],
    Living: [],
    Table: [],
    Jewelery: [],
    'Beauty + Wellness': [],
    'Kids + Baby': [],
  };
  if (productCategories.length > 0) {
    for (let i = 0; i < productCategoriesMatcher?.length; i++) {
      for (let j = 0; j < productCategories?.length; j++) {
        if (productCategories[j] === productCategoriesMatcher[i].value) {
          extractedProductCategories[
            productCategoriesMatcher[i]['parent']
          ].push(productCategoriesMatcher[i].value);
        }
      }
    }
  }
  return extractedProductCategories;
};

export const getSortedProducts = (
  products = [],
  sort_by = ApiProductSortTypesEnums.AlphaAsc
) => {
  if (sort_by === ApiProductSortTypesEnums.AlphaAsc) {
    return orderBy(products, (a) => a.item_name, ['asc']);
  } else if (sort_by === ApiProductSortTypesEnums.AlphaDesc) {
    return orderBy(products, (a) => a.item_name, ['desc']);
  } else if (sort_by === ApiProductSortTypesEnums.CreatedDesc) {
    return orderBy(products, (a) => new Date(a.creation), ['desc']);
  } else if (sort_by === ApiProductSortTypesEnums.Created) {
    return orderBy(products, (a) => new Date(a.creation), ['asc']);
  } else if (sort_by === ApiProductSortTypesEnums.PriceAsc) {
    return orderBy(products, (a) => a.fob_usd, ['desc']);
  } else if (sort_by === ApiProductSortTypesEnums.PriceDesc) {
    return orderBy(products, (a) => a.fob_usd, ['asc']);
  }
};
export const isRedirectPathCurrentPath = (history, targetPath) => {
  const currentPathName = history.location.pathname;

  return currentPathName === targetPath;
};

export const imageType = {
  featured_image: 'featuredImage',
  top_banner: 'topBannerImage',
  split_left_image: 'splitLeftImage',
  split_right_image: 'splitRightImage',
  bottom_banner: 'bottomBannerImage',
};

export const initializeImageObject = (position) => {
  return {
    file: null,
    objectUrl: null,
    position: position,
  };
};

export const initializeImageObjectWithType = (position, type) => {
  return {
    type: type,
    file: null,
    objectUrl: null,
    position: position,
  };
};

export const getCroppedImageFile = async (imageFile) => {
  if (!imageFile.name) {
    return;
  }
  const transformedFile = await transform(imageFile, 1000, 1000);
  let croppedImageFile = {};
  croppedImageFile.originFileObj = transformedFile;
  croppedImageFile.url = URL.createObjectURL(imageFile);
  croppedImageFile.name = imageFile.name;
  croppedImageFile.type = imageFile.type;
  croppedImageFile.size = transformedFile.size;
  croppedImageFile.uid = imageFile.uid;
  return croppedImageFile;
};

export const getAreaSort = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};
export const getParsedFormItem = (
  isCheckboxes = false,
  label = '',
  fieldName = '',
  placeholder = '',
  docs = {},
  data = [],
  rules = [],
  onChange = () => null,
  t = () => null
) => {
  const { Item } = Form;
  const { Option } = Select;
  const getCheckboxesOrSelect = isCheckboxes ? (
    <Checkbox.Group onChange={onChange}>
      <Row>
        {data.map((bc, index) => (
          <Col key={index}>
            <Checkbox value={bc.value} style={{ lineHeight: 2 }}>
              {t(bc.label)}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  ) : (
    <Select
      showSearch
      placeholder={t(placeholder)}
      autoComplete="none"
      mode="multiple"
      getPopupContainer={(trigger) => trigger.parentNode}
      onChange={onChange}
    >
      {data.map((dataValue) => (
        <Option key={dataValue.value} value={dataValue.value}>
          {t(dataValue.name || dataValue.label)}
        </Option>
      ))}
    </Select>
  );

  const getInitialValue =
    typeof docs[fieldName] === 'string'
      ? JSON.parse(docs[fieldName] || '[]')
      : docs[fieldName];

  const getShouldUpdate = (prevValues = {}, curValues = {}) =>
    prevValues[fieldName] !== curValues[fieldName];

  return (
    <Item shouldUpdate={getShouldUpdate}>
      {() => {
        return (
          <Item
            name={fieldName}
            label={label}
            initialValue={getInitialValue}
            rules={rules}
          >
            {getCheckboxesOrSelect}
          </Item>
        );
      }}
    </Item>
  );
};
export const getStringifiedValues = (docs = {}, fieldNames = []) => {
  let newValues = {};

  for (let fieldName of fieldNames) {
    if (Array.isArray(docs[fieldName])) {
      newValues = {
        ...newValues,
        [fieldName]: JSON.stringify(docs[fieldName]),
      };
    } else {
      if (typeof docs[fieldName] === 'string') {
        if (Array.isArray(JSON.parse(docs[fieldName]))) {
          newValues = {
            ...newValues,
            [fieldName]: JSON.stringify(docs[fieldName]),
          };
        } else {
          newValues = {
            ...newValues,
            [fieldName]: '[]',
          };
        }
      } else {
        newValues = {
          ...newValues,
          [fieldName]: '[]',
        };
      }
    }
  }
  return newValues;
};

export const getParsedValues = (docs = {}, fieldNames = []) => {
  let newValues = {};

  for (let fieldName of fieldNames) {
    if (Array.isArray(docs[fieldName])) {
      newValues = {
        ...newValues,
        [fieldName]: docs[fieldName],
      };
    } else {
      if (typeof docs[fieldName] === 'string') {
        let value = docs[fieldName];
        let newValue = value.replaceAll('``', '"');
        let finalValue = JSON.parse(newValue);
        let isArry = Array.isArray(finalValue);

        if (isArry) {
          newValues = {
            ...newValues,
            [fieldName]: finalValue,
          };
        } else {
          newValues = {
            ...newValues,
            [fieldName]: [],
          };
        }
      } else {
        newValues = {
          ...newValues,
          [fieldName]: [],
        };
      }
    }
  }

  return newValues;
};
export const getMenuClicksMixpanelAction = (menu, user) => {
  if (menu.key === SideMenuKeysEnums.Home) {
    handleNavigationClicks(MixpanelActions['Navigate to Homepage'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums.Market) {
    handleNavigationClicks(MixpanelActions['Navigate to Apply to Market'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums.Products) {
    handleNavigationClicks(MixpanelActions['Navigate to Products'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums.Customers) {
    handleNavigationClicks(MixpanelActions['Navigate to Customers'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums.Orders) {
    handleNavigationClicks(MixpanelActions['Navigate to Orders'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums.payments) {
    handleNavigationClicks(MixpanelActions['Navigate to Payments'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums.CashFlow) {
    handleNavigationClicks(MixpanelActions['Navigate to Cash Flow'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums.UserManagement) {
    handleNavigationClicks(MixpanelActions['Navigate to Users'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums.MyProfile) {
    handleNavigationClicks(MixpanelActions['Navigate to Profile'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums['product-attributes']) {
    handleNavigationClicks(MixpanelActions['Navigate to Product Attributes'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums.resources) {
    handleNavigationClicks(MixpanelActions['Navigate to Resources'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums.PaymentMethod) {
    handleNavigationClicks(MixpanelActions['Navigate to Payment Methods'], {
      user_information: user,
    });
  } else if (menu.key === SideMenuKeysEnums.ShopifyIntegration) {
    handleNavigationClicks(
      MixpanelActions['Navigate to Shopify Integrations'],
      { user_information: user }
    );
  }
};

export const handleUnwantedSelection = (currentValue, subject, field, form) => {
  if (currentValue[currentValue.length - 1] === subject) {
    form.setFieldsValue({
      [field]: [subject],
    });
  } else {
    form.setFieldsValue({
      [field]: currentValue.filter((value) => value !== subject),
    });
  }
};

export const escapeString = (str) => {
  if (!str) {
    return;
  }
  return str.replace(/"/g, '\\"');
};

export const validateSpecialCharactersRequiredField = (_, value) => {
  if (!value && _.required) {
    return Promise.reject(new Error('This field is required!'));
  }

  if (!value || !value.match(specialCharacters)) {
    return Promise.reject(
      new Error(
        'Please do not include these characters: \\ @ " / £ % # € ^ & = : ; | ? ~ ` { }  [  ]  <  >  æ'
      )
    );
  }
  return Promise.resolve();
};

export const validateRequiredField = (_, value) => {
  if (!value && _?.field !== 'quantity_available' && value !== 0) {
    return Promise.reject(new Error('This field is required!'));
  }
  if (value && value < _?.min) {
    return Promise.reject(
      new Error(`Value must be greater than or equal to ${_?.min}!`)
    );
  }

  return Promise.resolve();
};

export const getSumOfPercentage = (value) => {
  return value;
};

export const validateTotalPercentage = (_, form) => {
  const sumPercentage = form.getFieldValue()?.variantComposition?.reduce(
    (accumulator, currentValue) =>
      Number(accumulator) + Number(currentValue?.percentage),

    0
  );
  getSumOfPercentage(sumPercentage);

  return Promise.resolve();
};

export const scrollToFormInput = (form, inputName) => {
  form.scrollToField([inputName], {
    behavior: 'smooth',
    block: 'center',
    inline: 'start',
    skipOverflowHiddenElements: true,
  });
};

// reducer helpers

// product
export const mapProductData = (data = {}) => {
  const isDataAvailable = Object.keys(data);
  const details = {};

  if (!isDataAvailable) {
    return {};
  }

  details['data'] = (data?.docs || [])[0];
  details['attachments'] =
    data?.docinfo?.attachments.length > 0
      ? data?.docinfo?.attachments.map((image) => ({
          ...image,
          url: image.file_url,
        }))
      : [];

  return details;
};

export const mapSlotPricing = (pricingRules = []) => {
  if (pricingRules.length === 0) {
    return [];
  }
  // discount_amount
  // max_amt
  const defaultPricingRules = [
    {
      key: 'discountRange1',
      quantity: '0-24',
      dataIndex: '0-24',
    },
    {
      key: 'discountRange2',
      quantity: '25-49',
      dataIndex: '25-49',
    },
    {
      key: 'discountRange3',
      quantity: '50-99',
      dataIndex: '50-99',
    },
    {
      key: 'discountRange4',
      quantity: '100-199',
      dataIndex: '100-199',
    },
    {
      key: 'discountRange5',
      quantity: '200+',
      dataIndex: '200-1000',
    },
  ];

  let newPricingRules = [...defaultPricingRules];
  let updatedPricingRule = [...pricingRules].map((pRule) => ({
    min: pRule.min,
    max: pRule.max,
    discount_amount: pRule?.price,
    max_amt: pRule?.price,
    id: pRule?.id || undefined,
  }));
  for (let i = 0; i < updatedPricingRule.length; i++) {
    const range = `${updatedPricingRule[i].min}-${updatedPricingRule[i].max}`;
    const findIndex = defaultPricingRules.findIndex(
      (pRule) => pRule.dataIndex === range
    );
    if (findIndex >= 0) {
      newPricingRules[findIndex] = {
        ...defaultPricingRules[findIndex],
        ...updatedPricingRule[i],
      };
    }
  }
  return newPricingRules;
};

export const extractAttributes = (options = []) => {
  if (!options) {
    return [];
  }
  let allAttributes = [];

  for (let i = 0; i < options.length; i++) {
    allAttributes = [
      ...allAttributes,
      ...options[i].item_attribute_values.filter(
        (attr) => attr.abbr && attr.attribute_value
      ),
    ];
  }
  return allAttributes;
};

export const getVariantsTitle = (attributes = []) => {
  if (attributes.length === 0) {
    return 'No name';
  }
  let variantTitle = attributes.map((attr, index) => {
    if (attributes.length - 1 === index) {
      return `${attr.attribute_value}`;
    } else {
      return `${attr.attribute_value}|`;
    }
  });
  return variantTitle;
};

export const removeModifiedFieldFromProductAttribute = (attributes = []) => {
  if (attributes.length === 0) {
    return [];
  }
  let _attributes = [...attributes];
  let new_attributes = [];

  for (let i = 0; i < _attributes.length; i++) {
    let new_attribute = { ..._attributes[i] };
    delete new_attribute['modified_by'];
    delete new_attribute['modified'];
    let new_attributes_values = new_attribute.item_attribute_values.map(
      (attribute_value) => {
        let new_attribute_value = { ...attribute_value };
        delete new_attribute_value['modified_by'];
        delete new_attribute_value['modified'];
        return new_attribute_value;
      }
    );
    new_attribute['item_attribute_values'] = new_attributes_values;
    new_attributes.push(new_attribute);
  }
  return new_attributes || [];
};

export const getAttributesPayload = (
  attributes = [],
  attributes_index_to_update = []
) => {
  if (attributes.length === 0) {
    return [];
  }
  let _attributes = attributes_index_to_update.map((opt) => ({
    ...attributes[opt],
  }));

  let new_attributes = [];

  for (let i = 0; i < _attributes.length; i++) {
    let new_attribute = { ..._attributes[i] };
    delete new_attribute['modified_by'];
    delete new_attribute['modified'];
    let new_attributes_values = new_attribute.item_attribute_values.map(
      (attribute_value) => {
        let new_attribute_value = { ...attribute_value };
        delete new_attribute_value['modified_by'];
        delete new_attribute_value['modified'];
        new_attribute_value['abbr'] = new_attribute_value['attribute_value'];
        return new_attribute_value;
      }
    );
    new_attribute['item_attribute_values'] = new_attributes_values;
    new_attributes.push(new_attribute);
  }
  return new_attributes || [];
};

export const isPercentageValid = (_, value) => {
  if (!value || value.length === 0) {
    return Promise.reject(new Error('This field is required!'));
  }

  if (!value || validURL.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject(new Error('Please enter a valid website'));
};

export const getProductAttributesWithCorrectAttributeName = (
  product_attributes = []
) => {
  if (!product_attributes) return [];

  let new_product_attributes = product_attributes?.map((attr) => ({
    ...attr,
    attribute_name: attr.attribute_name.split('-')[0],
  }));

  let options = [];
  for (let i = 0; i < new_product_attributes.length; i++) {
    options.push(new_product_attributes[i]);
  }

  return options;
};

export const getVariantsOptionTitle = (option, company) => {
  if (!option || !company) {
    return '';
  }
  return option.concat('-').concat(company);
};

export const getVariantsPayload = (raw_variants, company) => {
  if (!raw_variants.length) {
    return [];
  }

  let ripe_variants = [];
  let ripe_attributes = [];
  for (let i = 0; i < raw_variants.length; i++) {
    let new_variant_obj = {
      fob_usd: raw_variants[i]['fob_usd'],
      msrp_usd: raw_variants[i]['msrp_usd'],
      title: raw_variants[i]['title'],
    };

    if (raw_variants[i].title) {
      for (const property in raw_variants[i]) {
        let new_attribute_obj = {
          doctype: DocTypeEnums.ITEM_VARIANT_ATTRIBUTE,
          parentType: DocTypeEnums.ITEM,
        };
        if (
          property !== 'fob_usd' &&
          property !== 'msrp_usd' &&
          property !== 'title' &&
          property !== 'name' &&
          property !== 'image' &&
          property !== 'shopify_variant_id'
        ) {
          new_variant_obj[getVariantsOptionTitle(property, company)] =
            raw_variants[i][property];
          new_attribute_obj['attribute'] = getVariantsOptionTitle(
            property,
            company
          );
          ripe_attributes.push(new_attribute_obj);
        }
      }
    }

    ripe_variants.push(new_variant_obj);
  }
  return {
    variant: ripe_variants,
    attribute: ripe_attributes,
  };
};

// export const extractVariantsValues = (variants = []) => {
//   if (!variants) {
//     return [];
//   }

//   let extracted_variants_values_and_options = {};
//   for (let i = 0; i < variants.length; i++) {
//     for (let j = 0; j < variants[i].attributes.length; j++) {
//       const option_name = variants[i].attributes[j]?.attribute.split('-')[0];
//       const option_value = variants[i].attributes[j]?.attribute_value;
//       let findIndex = -1;
//       if (extracted_variants_values_and_options[option_name]) {
//         findIndex =
//           extracted_variants_values_and_options[option_name].indexOf(
//             option_value
//           );
//       }

//       // if (findIndex < 0) {
//       //   extracted_variants_values_and_options[option_name] = (
//       //     extracted_variants_values_and_options[option_name] || []
//       //   ).push(option_value);
//       // }
//     }
//   }

//   return extracted_variants_values_and_options;
// };

export const doesVariantExist = (options, title) => {
  if (!options) {
    return false;
  }
  let exists = false;
  for (let i = 0; i < options.length; i++) {
    let split_submitted_variant_title = options[i].title.split('|');
    let split_unsubmitted_variant_title = title.split('|');
    const isAvailable = isEqual(
      split_submitted_variant_title.sort(),
      split_unsubmitted_variant_title.sort()
    );
    if (isAvailable) {
      exists = isAvailable;
    }
  }
  return exists;
};

export const validateVariantsFields = (_, value) => {
  if (!value && _.required) {
    return Promise.reject(new Error('This field is required!'));
  }

  return Promise.resolve();
};

export const getOptionValues = (options) => {
  const selected_list = JSON.stringify(options);
  const new_selected_list = JSON.parse(selected_list.split()[0]);
  const final_values = {};
  for (let i = 0; i < new_selected_list.length; i++) {
    const obj = JSON.parse(new_selected_list[i]);

    const property = Object.keys(obj)[0];
    const values = Object.values(obj)[0];
    if (Object.keys(final_values).length > 0) {
      for (const key in final_values) {
        if (key === property) {
          let findIndex = final_values[property].indexOf(values[0]);
          if (findIndex < 0) {
            final_values[property] = [...final_values[property], ...values];
          }
        } else {
          final_values[property] = values;
        }
      }
    } else {
      final_values[property] = values;
    }
  }
  return final_values;
};

export const getVariantsAttributes = (variants = [], company) => {
  if (variants.length === 0) {
    return [];
  }

  const submitted_variants = variants.filter((variant) => variant.name);
  const unsubmitted_variants = variants.filter((variant) => variant.title);

  let final_attributes = [];
  for (let i = 0; i < unsubmitted_variants.length; i++) {
    for (const property in unsubmitted_variants[i]) {
      if (
        property !== 'fob_usd' &&
        property !== 'msrp_usd' &&
        property !== 'title'
      ) {
        const find_index = final_attributes.indexOf(
          getVariantsOptionTitle(property, company)
        );
        if (find_index < 0 && getVariantsOptionTitle(property, company)) {
          final_attributes.push(getVariantsOptionTitle(property, company));
        }
      }
    }
  }
  for (let i = 0; i < submitted_variants.length; i++) {
    for (let j = 0; j < submitted_variants[i].attributes.length; j++) {
      const find_index = final_attributes.indexOf(
        submitted_variants[i].attributes[j]?.attribute
      );
      if (find_index < 0) {
        final_attributes.push(submitted_variants[i].attributes[j]?.attribute);
      }
    }
  }
  return final_attributes;
};

export const validateRequiredAndValueField = (
  _,
  value,
  index,
  option_list,
  all_values,
  setErrorValue = () => null
) => {
  if (!value) {
    setErrorValue(true);
    return Promise.reject(new Error('This field is required!'));
  }
  const is_option_name_available = option_list[index].option_name;
  if (!is_option_name_available) {
    setErrorValue(true);
    return Promise.reject(new Error(`Please select option name ${index + 1}`));
  }

  if (value) {
    const new_all_values = all_values.map((val) => val.attribute_value);
    const error_list = [];
    for (let i = 0; i < value.length; i++) {
      const findIndex = new_all_values.findIndex(
        (val) => val.toLowerCase() === value[i].toLowerCase()
      );
      if (findIndex >= 0) {
        error_list.push(value[i]);
      }
    }
    if (error_list.length > 0) {
      setErrorValue(true);
      return Promise.reject(
        new Error("You've already used the option value .")
      );
    }
  }
  setErrorValue(false);
  return Promise.resolve();
};

export const getPreviousSubmittedOptions = (variants) => {
  if (!variants) {
    return [];
  }
  let new_variants = [...variants];
  let new_options = {};
  for (let i = 0; i < new_variants.length; i++) {
    let attributes = new_variants[i].attributes;

    for (let j = 0; j < attributes.length; j++) {
      let attribute_name = attributes[j].attribute.split('-')[0];
      let attribute_value = attributes[j].attribute_value;

      const findIndex = Object.keys(new_options).indexOf(attribute_name);
      if (findIndex >= 0) {
        let current_attributes_values = new_options[attribute_name];
        let find_value_index =
          current_attributes_values.indexOf(attribute_value);
        if (find_value_index < 0) {
          new_options[attribute_name] = [
            ...current_attributes_values,
            attribute_value,
          ];
        }
      } else {
        new_options[attribute_name] = [attribute_value];
      }
    }
  }

  return new_options;
};

export const getFileExtension = (filename) => {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
};

export const validateRequiredAndMaterialCompositionField = (
  _,
  value,
  isPercentageValid = () => null,
  setErrorValue = () => null
) => {
  if (!value) {
    setErrorValue(true);
    return Promise.reject(new Error('This field is required!'));
  }
  if (isPercentageValid() !== 100) {
    setErrorValue(true);
    return Promise.reject(new Error('Total percentage should be 100!'));
  }
  setErrorValue(false);
  return Promise.resolve();
};

export const handleOnSelectCategory = (value, productTree, setProductTree) => {
  product_categories?.map((option) => {
    if (option.options?.includes(value)) {
      setProductTree({
        ...productTree,
        category: {
          ...productTree?.category,
          parent_category: option.name,
          category: value,
        },
      });
    }
  });
};

//set default tags from the product object
export const setDefaultProductCategoryTree = (
  product,
  productTree,
  setProductTree
) => {
  if (product?.lead_time_days && product?.category) {
    const _lead_time = lead_time_options?.find(
      (opt) => opt.value === product?.lead_time_days
    );
    product_categories?.map((option) => {
      if (option.options?.includes(product?.category)) {
        setProductTree({
          ...productTree,
          category: {
            ...productTree['category'],
            parent_category: option.name,
            category: product?.category,
            sub_category: product?.sub_category,
          },
          lead_time: {
            lead_time: _lead_time?.label,
          },
        });
      }
    });
  }
};

export const handleTags = (
  corporate_values = '',
  product_origin = '',
  productTree
) => {
  let tags = ['Unverified'];
  if (product_origin) {
    tags = [...tags, product_origin];
  }
  if (corporate_values) {
    let makerValues =
      typeof corporate_values === 'string'
        ? JSON.parse(corporate_values)
        : corporate_values;
    tags = [...tags, ...makerValues];
  }
  if (productTree) {
    tags = [
      ...tags,
      productTree['category']?.parent_category,
      productTree['category']?.category,
      productTree['category']?.sub_category,
      productTree['lead_time']?.lead_time,
    ];
  }
  const filteredTags = tags?.filter((tag) => {
    return tag !== undefined;
  });
  return filteredTags?.join();
};

export const handleVerifiedItemTags = (
  tags,
  lead_time_days,
  previousLeadTime
) => {
  const currentTags = tags?.split(',');
  const _lead_time = lead_time_options?.find(
    (opt) => opt.value === lead_time_days
  );
  let _tags;
  if (lead_time_days !== previousLeadTime) {
    _tags = currentTags?.map((tag) => {
      return tag.trim();
    });
    lead_time_options?.forEach((option) => {
      const index = _tags?.indexOf(option?.label);
      if (index >= 0) {
        _tags?.splice(index, 1);
      }
    });
    _tags = [..._tags, _lead_time?.label];
  }
  return _tags?.join();
};

export const beforeUpload = async (file, message, t) => {
  if (file.size / fileSizes.KB / fileSizes.KB > 19.99) {
    message.error(
      t(
        'File not uploaded. File size is greater than the maximum limit of 19MB'
      ),
      10
    );
    return false;
  } else return true;
};

export const extractTranformedAttributes = (variants = [], company = '') => {
  if (variants.length === 0) {
    return {
      options: {},
      attributes: [],
      original_attributes: [],
    };
  }
  const filter_out_submitted_variants = variants.filter(
    (variant) => !variant.doctype
  );

  let obj = {};
  for (let i = 0; i < filter_out_submitted_variants.length; i++) {
    const current_variant = filter_out_submitted_variants[i];

    for (let property in current_variant) {
      if (
        property &&
        property !== 'name' &&
        property !== 'fob_usd' &&
        property !== 'msrp_usd' &&
        property !== 'image' &&
        property !== 'name' &&
        property !== 'title' &&
        property !== 'shopify_variant_id'
      ) {
        const has_properties = Object.keys(obj).length > 0;
        if (has_properties) {
          const has_values = obj[property]?.length > 0;
          if (has_values) {
            const find_index_2 = obj[property].indexOf(
              current_variant[property]
            );

            if (find_index_2 < 0) {
              obj[property] = [...obj[property], current_variant[property]];
            }
          } else {
            obj[property] = [current_variant[property]];
          }
        } else {
          obj[property] = [current_variant[property]];
        }
      }
    }
  }
  return {
    options: obj,
    attributes: Object.keys(obj),
    original_attributes: Object.keys(obj).map((attribute) =>
      attribute.concat('-').concat(company)
    ),
  };
};

export const getUserAttributes = (productAttributes = []) => {
  if (productAttributes === 0) {
    return;
  }
  return productAttributes.map(
    (attribute) => attribute.attribute_name.split('-')[0]
  );
};

export const filterOutExistAttributes = (
  productAttributes = [],
  allProductAttributes
) => {
  if (allProductAttributes === 0) {
    return;
  }
  const filteredOutExistAttributes = [];
  for (let i = 0; i < allProductAttributes.length; i++) {
    const findIndex = productAttributes.findIndex(
      (attribute) => attribute.attribute_name === allProductAttributes[i].value
    );
    if (findIndex < 0) {
      filteredOutExistAttributes.push(allProductAttributes[i]);
    }
  }
  return filteredOutExistAttributes;
};

export const getAttributeNamesPayload = (
  values = [],
  existingAttributes = [],
  company = ''
) => {
  if (values.length === 0) {
    return;
  }
  let optionNamesToUpdate = [];
  let optionNamesToCreate = [];
  let optionNamesToDelete = [...existingAttributes];
  for (let i = 0; i < values.length; i++) {
    if (existingAttributes.length > 0) {
      const findIndex = existingAttributes.findIndex(
        (existingAttribute) =>
          existingAttribute.attribute_name.split('-')[0] === values[i]
      );
      if (findIndex >= 0) {
        optionNamesToUpdate.push({
          ...existingAttributes[findIndex],
          attribute_name: values[i].concat('-').concat(company),
        });
        optionNamesToDelete = optionNamesToDelete.filter(
          (optionName) =>
            optionName.attribute_name !==
            existingAttributes[findIndex].attribute_name
        );
      } else {
        optionNamesToCreate.push({
          attribute_name: values[i],
          company: company,
          doctype: DocTypeEnums.ITEM_ATTRIBUTE,
          item_attribute_values: [],
        });
      }
    }
  }
  return {
    option_names_to_update: optionNamesToUpdate,
    option_names_to_create: optionNamesToCreate,
    option_names_to_delete: optionNamesToDelete,
  };
};

export const doesOptionNameExist = (value, productAttributes = []) => {
  return productAttributes
    .map((attribute) => attribute.attribute_name.split('-')[0])
    .indexOf(value) >= 0
    ? true
    : false;
};

export const getAttributeNames = (productAttributes = []) => {
  if (productAttributes.length === 0) {
    return [];
  }
  return productAttributes.map(
    (attribute) => attribute.attribute_name.split('-')[0]
  );
};

export const getAttributeValues = (optionName, productAttributes = []) => {
  if (!optionName) {
    return [];
  }
  const findAttribute = productAttributes.find(
    (attribute) => attribute?.attribute_name.split('-')[0] === optionName
  );

  return findAttribute?.item_attribute_values.map(
    (attribute) => attribute?.attribute_value
  );
};

export const getAttributeValuesPayload = (
  values = {},
  productAttributes = [],
  company
) => {
  const optionName = values.option_name;
  const optionValues = values.option_values;

  const findAttribute = productAttributes.find(
    (attribute) => attribute.attribute_name.split('-')[0] === optionName
  );
  const existingAttributeValues = findAttribute.item_attribute_values.map(
    (attribute) => attribute
  );
  let newAttributeValues = [];
  for (let i = 0; i < optionValues.length; i++) {
    const findIndex = existingAttributeValues.findIndex(
      (attribute) =>
        attribute.attribute_value.toLowerCase() ===
        optionValues[i].toLowerCase()
    );
    if (findIndex >= 0) {
      let newObj = {
        ...existingAttributeValues[findIndex],
        attribute_value: optionValues[i],
        abbr: optionValues[i].toUpperCase(),
      };
      newAttributeValues.push(newObj);
    } else {
      newAttributeValues.push({
        docstatus: 0,
        company: company,
        doctype: DocTypeEnums.ITEM_ATTRIBUTE_VALUE,
        parentfield: 'item_attribute_values',
        parenttype: DocTypeEnums.ITEM_ATTRIBUTE,
        abbr: optionValues[i].toUpperCase(),
        attribute_value: optionValues[i],
      });
    }
  }

  return {
    ...findAttribute,
    item_attribute_values: [...newAttributeValues],
  };
};
export const handleItemsToBulkPush = (items, itemWithErrors) => {
  const all_required_fields = [
    { field: 'item_code_ref' },
    { field: 'item_name' },
    { field: 'lead_time_days' },
    { field: 'category' },
    { field: 'min_order_qty' },
    { field: 'description' },
  ];
  items?.forEach((item) => {
    for (let i = 0; i < all_required_fields.length; i++) {
      if (!item[all_required_fields[i].field]) {
        itemWithErrors.push(item?.item_name);
      }
    }
  });
  const itemsToPushToMarket = items
    ?.filter((item) => {
      return itemWithErrors.indexOf(item?.item_name) === -1;
    })
    ?.map((item) => {
      return item?.name;
    });
  return itemsToPushToMarket;
};

export const attributeNameValidator = (_, value, index, attributes) => {
  let exists = false;
  for (let i = 0; i < attributes.length; i++) {
    if (attributes[i]['attribute_name'] === value && i !== index) {
      exists = true;
    }
  }
  if (!exists) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error(`You have already used the option name ${value}`)
  );
};

export const getGeneratedVariants = (variants = []) => {
  if (variants.length === 0) {
    return [];
  }
  let obj = {};
  for (let i = 0; i < variants.length; i++) {
    const currentVariant = variants[i];
    const optionName = currentVariant['attribute_name'];
    const optionValue = currentVariant['attribute_value'];
    if (optionName) {
      obj[optionName] = optionValue;
    }
  }
  return newGenerateVariants([obj]) || [];
};

export const getInitialVariants = (variants = []) => {
  if (variants.length === 0) {
    return [];
  }

  let obj = {};
  const generatedVariants = [];
  for (let i = 0; i < variants.length; i++) {
    const currentVariant = variants[i];
    const attributes = currentVariant?.attributes;
    let currentObj = {};
    for (let j = 0; j < attributes.length; j++) {
      const findIndex = Object.keys(obj).indexOf(
        attributes[j]?.attribute.split('-')[0]
      );
      if (findIndex >= 0) {
        const currentValues = obj[attributes[j]?.attribute.split('-')[0]];
        const findIndex2 = currentValues.indexOf(
          attributes[j]?.attribute_value
        );
        if (findIndex2 < 0) {
          obj[attributes[j]?.attribute.split('-')[0]] = [
            ...obj[attributes[j]?.attribute.split('-')[0]],
            attributes[j]?.attribute_value,
          ];
          currentObj[attributes[j]?.attribute.split('-')[0]] = [
            ...obj[attributes[j]?.attribute.split('-')[0]],
            attributes[j]?.attribute_value,
          ];
        }
      } else {
        obj[attributes[j]?.attribute.split('-')[0]] = [
          attributes[j]?.attribute_value,
        ];
        currentObj[attributes[j]?.attribute.split('-')[0]] = [
          attributes[j]?.attribute_value,
        ];
      }
    }
    generatedVariants.push({
      ...newGenerateVariants([obj])[i],
      fob_usd: Number(variants[i]?.fob_usd),
      msrp_usd: Number(variants[i]?.msrp_usd),
      name: variants[i]?.name,
      shopify_variant_id: variants[i]?.shopify_variant_id,
    });
  }

  return generatedVariants || [];
};

export const getInitialAttributes = (variants = []) => {
  if (variants.length === 0) {
    return [
      { attribute_name: '', attribute_value: [] },
      { attribute_name: '', attribute_value: [] },
      { attribute_name: '', attribute_value: [] },
    ];
  }
  let obj = {};
  let generatedAttributes = [
    { attribute_name: '', attribute_value: [] },
    { attribute_name: '', attribute_value: [] },
    { attribute_name: '', attribute_value: [] },
  ];
  for (let i = 0; i < variants.length; i++) {
    const currentVariant = variants[i];
    const attributes = currentVariant?.attributes;
    for (let j = 0; j < attributes.length; j++) {
      const findIndex = Object.keys(obj).indexOf(
        attributes[j]?.attribute.split('-')[0]
      );
      if (findIndex >= 0) {
        const currentValues = obj[attributes[j]?.attribute.split('-')[0]] || [];
        const findIndex2 = currentValues.indexOf(
          attributes[j]?.attribute_value
        );
        if (findIndex2 < 0) {
          obj[attributes[j]?.attribute.split('-')[0]] = [
            ...obj[attributes[j]?.attribute.split('-')[0]],
            attributes[j]?.attribute_value,
          ];
        }
      } else {
        obj[attributes[j]?.attribute.split('-')[0]] = [
          attributes[j]?.attribute_value,
        ];
      }
    }
  }
  let count = 0;
  for (let property in obj) {
    if (count > generatedAttributes.length - 1) {
      generatedAttributes.push({ attribute_name: '', attribute_value: [] });
    }
    generatedAttributes[count]['attribute_name'] = property;
    generatedAttributes[count]['attribute_value'] = obj[property];
    count += 1;
  }

  return (
    generatedAttributes || [
      { attribute_name: '', attribute_value: [] },
      { attribute_name: '', attribute_value: [] },
      { attribute_name: '', attribute_value: [] },
    ]
  );
};

export const mapCurrentVariantsToPreviousVariantsOnAdd = (
  currentVariants,
  previousVariants
) => {
  if (previousVariants.length === 0) {
    return currentVariants || [];
  }
  let newPreviousVariants = [...previousVariants];
  let newCurrentVariants = [...currentVariants];
  let newVariants = [];
  for (let i = 0; i < newCurrentVariants.length; i++) {
    if (newPreviousVariants[i]?.title) {
      newVariants.push({
        ...newCurrentVariants[i],
        fob_usd: newPreviousVariants[i]?.fob_usd,
        msrp_usd: newPreviousVariants[i]?.msrp_usd,
        name: newPreviousVariants[i]?.name,
        shopify_variant_id: newPreviousVariants[i]?.shopify_variant_id,
      });
    } else {
      newVariants.push(newCurrentVariants[i]);
    }
  }
  return newVariants || [];
};

export const mapCurrentVariantsToPreviousVariantsOnRemove = (
  currentVariants,
  previousVariants
) => {
  if (previousVariants.length === 0) {
    return currentVariants || [];
  }
  let newPreviousVariants = [...previousVariants];
  let newCurrentVariants = [...currentVariants];
  let newVariants = [];
  for (let i = 0; i < newCurrentVariants.length; i++) {
    const findIndex = newPreviousVariants.findIndex(
      (variant) => variant?.title === newCurrentVariants[i]?.title
    );
    if (findIndex >= 0) {
      newVariants.push({
        ...newCurrentVariants[i],
        fob_usd: newPreviousVariants[findIndex]?.fob_usd,
        msrp_usd: newPreviousVariants[findIndex]?.msrp_usd,
        name: newPreviousVariants[findIndex]?.name,
        shopify_variant_id: newPreviousVariants[findIndex]?.shopify_variant_id,
      });
    } else {
      newVariants.push(newCurrentVariants[i]);
    }
  }
  return newVariants || [];
};

export const getVariantsToDelete = (
  currentVariantsToDelete,
  previousVariants,
  removedValue
) => {
  if (previousVariants.length === 0) {
    return [];
  }
  let variantsToDelete = [...currentVariantsToDelete];
  for (let i = 0; i < previousVariants.length; i++) {
    const titleList = previousVariants[i]?.title.split('/');
    const findIndex = titleList.indexOf(removedValue);
    if (findIndex >= 0 && previousVariants[i]?.name) {
      variantsToDelete.push(previousVariants[i]);
    }
  }
  return variantsToDelete;
};

export const getVariantsToCreatePayload = (variants, company) => {
  if (variants.length === 0) {
    return [];
  }
  const newVariants = [];
  for (let i = 0; i < variants.length; i++) {
    const currentVariant = variants[i];
    let obj = {};

    for (let property in currentVariant) {
      if (
        property !== 'fob_usd' &&
        property !== 'msrp_usd' &&
        property !== 'title' &&
        property !== 'image' &&
        property !== 'shopify_variant_id' &&
        property !== 'name'
      ) {
        const newProperty = property.concat('-').concat(company);
        obj[newProperty] = currentVariant[property];
      }
      if (property === 'fob_usd' || property === 'msrp_usd') {
        if (currentVariant[property]) {
          obj[property] = currentVariant[property];
        }
      }
    }
    if (variants[i]?.name) {
      obj = { ...obj, name: variants[i]?.name };
    }
    if (variants[i]?.shopify_variant_id) {
      obj = {
        ...obj,
        shopify_variant_id: variants[i]?.shopify_variant_id,
      };
    }
    newVariants.push(obj);
  }
  return newVariants;
};

export const getVariantsToUpdatePayload = (variants, company) => {
  if (variants.length === 0) {
    return [];
  }
  const newVariants = [];
  for (let i = 0; i < variants.length; i++) {
    const currentVariant = variants[i];
    let obj = {};

    for (let property in currentVariant) {
      obj['doctype'] = DocTypeEnums.ITEM;
      if (
        property !== 'fob_usd' &&
        property !== 'msrp_usd' &&
        property !== 'title' &&
        property !== 'image' &&
        property !== 'shopify_variant_id' &&
        property !== 'name'
      ) {
        const newProperty = property.concat('-').concat(company);
        obj[newProperty] = currentVariant[property];
      }
      if (
        property === 'fob_usd' ||
        property === 'msrp_usd' ||
        property === 'image' ||
        property === 'name'
      ) {
        if (currentVariant[property]) {
          obj[property] = currentVariant[property];
        }
      }
    }
    newVariants.push(obj);
  }
  return newVariants;
};

export const getProductAtrributesPayload = (variants, company) => {
  if (variants.length === 0) {
    return [];
  }
  const newVariants = [];
  for (let i = 0; i < variants.length; i++) {
    const currentVariant = variants[i];

    for (let property in currentVariant) {
      if (
        property !== 'fob_usd' &&
        property !== 'msrp_usd' &&
        property !== 'title' &&
        property !== 'image' &&
        property !== 'shopify_variant_id' &&
        property !== 'name'
      ) {
        let obj = {};
        const newProperty = property.concat('-').concat(company);
        obj['doctype'] = DocTypeEnums.ITEM_VARIANT_ATTRIBUTE;
        obj['parentype'] = DocTypeEnums.ITEM;
        obj['attribute'] = newProperty;
        newVariants.push(obj);
      }
    }
  }
  return newVariants;
};

export const clearDuplicatesOnAttributesPayload = (attributes) => {
  if (attributes.length === 0) {
    return [];
  }
  let newAttributes = [];
  for (let i = 0; i < attributes.length; i++) {
    const findIndex = newAttributes.findIndex(
      (attribute) => attribute.attribute === attributes[i].attribute
    );
    if (findIndex < 0) {
      newAttributes.push(attributes[i]);
    }
  }
  return newAttributes;
};

export const mapVariantsResponseWithReducerState = (
  updatedVariants,
  createdVariants,
  currentVariants
) => {
  let newCurrentVariants = [...currentVariants];
  if (updatedVariants.length > 0) {
    for (let i = 0; i < updatedVariants.length; i++) {
      const findIndex = newCurrentVariants.findIndex(
        (variant) => variant?.name === updatedVariants[i]?.name
      );
      if (findIndex >= 0) {
        let obj = {
          ...newCurrentVariants[findIndex],
          fob_usd: updatedVariants[i]?.fob_usd,
          msrp_usd: updatedVariants[i]?.msrp_usd,
        };
        newCurrentVariants[findIndex] = obj;
      }
    }
  }
  if (createdVariants.length > 0) {
    newCurrentVariants = [...newCurrentVariants, ...createdVariants];
  }
  return newCurrentVariants || [];
};

export const extendPreviousVariantInfo = (
  currentVariants,
  previousVariants
) => {
  if (previousVariants.length === 0) {
    return currentVariants;
  }
  let newCurrentVariants = [];
  for (let i = 0; i < currentVariants.length; i++) {
    const findIndex = previousVariants.findIndex(
      (variant) => variant?.name === currentVariants[i]?.name
    );
    let newObj = {};
    if (findIndex >= 0) {
      newObj = {
        ...previousVariants[findIndex],
        ...currentVariants[i],
      };
      delete newObj?.attributes;
      delete newObj?.modified;
      delete newObj?.modified_by;
      delete newObj?.item_code;
      delete newObj?.item_code_ref;
      newCurrentVariants.push(newObj);
    } else {
      newObj = { ...currentVariants[i] };
      newCurrentVariants.push(newObj);
    }
  }
  return newCurrentVariants;
};

export const cleanVariantPayload = (variants = []) => {
  if (variants.length === 0) {
    return [];
  }
  let variantsNotToPushToMarket = [];
  let variantsToPushToMarket = [];
  for (let i = 0; i < variants.length; i++) {
    let obj = { ...variants[i] };
    delete obj?.shopify_variant_id;
    delete obj?.name;
    if (variants[i]?.shopify_variant_id) {
      variantsToPushToMarket.push(obj);
    } else {
      variantsNotToPushToMarket.push(obj);
    }
  }
  return {
    variants_not_to_push_to_market: variantsNotToPushToMarket,
    variants_to_push_to_market: variantsToPushToMarket,
  };
};

// product restructure variants
export const getAllVariantsToBeDeleted = (variants, value) => {
  if (variants.length === 0) {
    return [];
  }
  let variantsToBeDeleted = [];
  for (let i = 0; i < variants.length; i++) {
    const valueIndex = Object.values(variants[i]).findIndex(
      (val) => val === value
    );
    if (valueIndex >= 0 && variants[i]?.name) {
      variantsToBeDeleted.push(variants[i]);
    }
  }
  return variantsToBeDeleted;
};

export const isOptionValueToBeDeleted = (variantsToBeDeleted, value) => {
  if (variantsToBeDeleted.length === 0) {
    return false;
  }
  for (let i = 0; i < variantsToBeDeleted.length; i++) {
    const valueIndex = Object.values(variantsToBeDeleted[i]).findIndex(
      (val) => val === value
    );
    if (valueIndex >= 0) {
      return true;
    }
  }
  return false;
};

export const updateVariantsToUpdate = (variantsToUpdate, value) => {
  if (variantsToUpdate.length === 0) {
    return [];
  }
  let newVariantsToUpdate = [];
  for (let i = 0; i < variantsToUpdate.length; i++) {
    const findIndex = variantsToUpdate[i].title
      .split('/')
      .findIndex((val) => val === value);
    if (findIndex < 0) {
      newVariantsToUpdate.push(variantsToUpdate[i]);
    }
  }
  return newVariantsToUpdate;
};

export const isValueExists = (value, variantsToDelete) => {
  if (variantsToDelete.length === 0) {
    return false;
  }
  for (let i = 0; i < variantsToDelete.length; i++) {
    const findIndex = variantsToDelete[i]?.title
      .split('/')
      .findIndex((val) => val === value);
    if (findIndex >= 0 && variantsToDelete[i]?.name) {
      return true;
    }
  }
  return false;
};

export const filterVariantsToDelete = (variants = [], value) => {
  if (variants.length === 0) {
    return [];
  }
  const newVariants = [];
  for (let i = 0; i < variants.length; i++) {
    const findIndex = variants[i]?.title
      .split('/')
      .findIndex((val) => val === value);
    if (findIndex < 0) {
      newVariants.push(variants[i]);
    }
  }
  return newVariants;
};

export const removeDuplicateVariantsToDelete = (
  previousVariantsToDelete,
  currentVariantsToDelete
) => {
  if (previousVariantsToDelete.length === 0) {
    return currentVariantsToDelete;
  }
  if (currentVariantsToDelete.length === 0) {
    return previousVariantsToDelete;
  }
  let newVariantsToDelete = [...previousVariantsToDelete];
  for (let i = 0; i < currentVariantsToDelete.length; i++) {
    const findIndex = previousVariantsToDelete.findIndex(
      (variant) => variant?.name === currentVariantsToDelete[i]?.name
    );
    if (findIndex < 0) {
      newVariantsToDelete.push(currentVariantsToDelete[i]);
    }
  }
  return newVariantsToDelete;
};

export const removeVariantsToBeDeleted = (
  variantsToDelete = [],
  formVariants = []
) => {
  if (formVariants.length === 0) {
    return [];
  }
  if (variantsToDelete.length === 0) {
    return formVariants;
  }
  let newFormVariants = [];
  for (let i = 0; i < formVariants.length; i++) {
    const findIndex = variantsToDelete.findIndex(
      (variant) => variant.title === formVariants[i].title
    );
    if (findIndex < 0) {
      newFormVariants.push(formVariants[i]);
    }
  }
  return newFormVariants;
};

export const mapVariantsToOldList = (oldList, newList) => {
  if (oldList.length === 0) {
    return newList;
  }
  if (newList.length === 0) {
    return oldList;
  }
  let newVariants = [];
  for (let i = 0; i < newList.length; i++) {
    const findIndex1 = oldList.findIndex((olEl, index) => index === i);
    const findIndex2 = oldList.findIndex(
      (olEl) => olEl?.title === newList[i]?.title
    );
    let obj = { ...newList[i] };
    if (findIndex1 >= 0 && findIndex2 < 0) {
      obj = {
        ...obj,
        name: oldList[findIndex1]?.name,
        image: oldList[findIndex1]?.image,
        shopify_variant_id: oldList[findIndex1]?.shopify_variant_id,
        fob_usd: oldList[findIndex1]?.fob_usd,
        msrp_usd: oldList[findIndex1]?.msrp_usd,
      };
    } else if (findIndex1 >= 0 && findIndex2 >= 0) {
      obj = {
        ...obj,
        name: oldList[findIndex2]?.name,
        image: oldList[findIndex2]?.image,
        shopify_variant_id: oldList[findIndex2]?.shopify_variant_id,
        fob_usd: oldList[findIndex2]?.fob_usd,
        msrp_usd: oldList[findIndex2]?.msrp_usd,
      };
    }
    newVariants.push(obj);
  }
  return newVariants;
};

export const getVariantsToCreate = (
  initialVariants = [],
  currentVariants = []
) => {
  if (initialVariants.length === 0) {
    return currentVariants;
  }
  let variantsToCreate = [];
  for (let i = 0; i < currentVariants.length; i++) {
    const findIndex = initialVariants.findIndex(
      (variant) => variant?.title === currentVariants[i]?.title
    );
    if (findIndex < 0) {
      variantsToCreate.push(currentVariants[i]);
    }
  }
  return variantsToCreate;
};

export const allocateVariantsToActions = (
  initialVariants = [],
  currentVariants = []
) => {
  let newVariantsToCreate = [];
  let newVariantsToUpdate = [];
  for (let i = 0; i < currentVariants.length; i++) {
    const findIndex = initialVariants.findIndex(
      (variant) =>
        variant?.title === currentVariants[i]?.title &&
        variant?.name === currentVariants[i]?.name
    );
    let initialVariant = initialVariants[findIndex];
    let newVariant = currentVariants[i];
    if (findIndex >= 0) {
      if (
        Number(initialVariant?.fob_usd) !== Number(newVariant?.fob_usd) ||
        Number(initialVariant?.msrp_usd) !== Number(newVariant?.msrp_usd)
      ) {
        newVariantsToUpdate.push(newVariant);
      }
    } else {
      newVariantsToCreate.push(newVariant);
    }
  }
  return {
    variants_to_create: newVariantsToCreate,
    variants_to_update: newVariantsToUpdate,
  };
};

export const mapVariantsTitleToInitialTitles = (
  initialVariants = [],
  currentVariants = []
) => {
  let newCurrentVariants = [];
  for (let i = 0; i < currentVariants.length; i++) {
    const findIndex = initialVariants.findIndex(
      (variant) => variant.name === currentVariants[i]?.name
    );
    if (findIndex >= 0) {
      newCurrentVariants.push(initialVariants[findIndex]?.title);
    }
  }
  return newCurrentVariants;
};

export const getVariantFullPayload = (currentVariants, initialVariants) => {
  if (initialVariants.length === 0) {
    return currentVariants;
  }
  let newCurrentVariants = [];
  for (let i = 0; i < currentVariants.length; i++) {
    const findIndex = initialVariants.findIndex(
      (variant) => variant?.name === currentVariants[i]?.name
    );
    let newObj = {};
    if (findIndex >= 0) {
      newObj = {
        ...initialVariants[findIndex],
        fob_usd: Number(currentVariants[i]?.fob_usd),
        msrp_usd: Number(currentVariants[i]?.msrp_usd),
      };
      delete newObj.modified_by;
      delete newObj.modified;

      newCurrentVariants.push(newObj);
    } else {
      newObj = { ...currentVariants[i] };
      newCurrentVariants.push(newObj);
    }
  }
  return newCurrentVariants;
};

export const validatePasswordField = (_, value) => {
  if (!value && _.required) {
    return Promise.reject(new Error('This field is required!'));
  }

  if (!value.match(passwordCharacters)) {
    return Promise.reject(
      new Error(
        'Password must be at least 8 characters long containing at least one uppercase, lowercase and number character!'
      )
    );
  }
  if (value.trim().length > 20) {
    return Promise.reject(
      new Error('Pasword should not exceed 20 characters!')
    );
  }
  return Promise.resolve();
};

export const extractProductName = (
  productName,
  userCompanyName,
  companyName
) => {
  let company = companyName || userCompanyName;
  return productName?.split('-')[0].concat('-').concat(company);
};

export const getProductStatus = (status) => {
  if (!status) return undefined;
  if (status.toLowerCase() === 'archived') {
    return 'ARCHIVED';
  }
  if (status.toLowerCase() === 'active') {
    return 'PUBLISHED';
  }
  if (status.toLowerCase() === 'all') {
    return undefined;
  }
};

export const getProductSortOptions = (direction, field) => {
  if (!direction || !field) {
    return {
      direction: 'DESC',
      field: 'CREATED',
    };
  }
  if (field === 'CREATED_TIME') {
    return {
      direction,
      field: 'CREATED',
    };
  }
  return {
    direction,
    field,
  };
};

export const getProductStatusFilter = (tab) => {
  if (!tab) {
    return undefined;
  }

  if (tab === 'all') {
    return undefined;
  } else if (tab === 'active') {
    return 'APPROVED';
  } else if (tab === 'archived') {
    return 'DECLINED';
  }
  return undefined;
};

export const getErrorMessageDisplay = (errors) => {
  if (errors.length === 0) {
    return;
  }
};

export const handleErrorMessage = (errorList = [], t) => {
  let errorDescription = 'An unexpected issue has occurred';
  let errorMessage = 'Error';
  if (errorList.length > 1) {
    errorMessage = 'The following item(s) require your attention';
    errorDescription = (
      <ul style={{ paddingInlineStart: 20, paddingTop: 10 }}>
        {errorList.map((err, index) => (
          <li key={`error-key-${index}`}>{t(err?.message)}</li>
        ))}
      </ul>
    );
  } else {
    errorDescription = errorList[0].message;
  }

  notification.error({
    message: <span style={{ fontWeight: 500 }}>{t(errorMessage)}</span>,
    description: errorDescription,
    placement: 'bottomLeft',
  });
};

export const getUserRole = (user) => {
  if (user.role === 'MAKER_ADMIN') {
    return 'Admin';
  }
  if (user.role === 'MAKER_STAFF') {
    return 'Staff';
  }

  return 'Staff';
};

export const getUsersTabStatus = (tabObj = {}) => {
  if (!tabObj?.tab) {
    return 'ALL';
  }
  return tabObj?.tab.toUpperCase();
};
// data?.makerUsers?.pageInfo
export const setPagination = (
  setSearchParams,
  buttonType = 'next',
  pageInfo = {},
  limit = 10
) => {
  if (buttonType === 'next') {
    setSearchParams((prev) => ({
      ...prev,
      first: limit,
      last: null,
      after: pageInfo?.endCursor,
      before: null,
    }));
  } else if (buttonType === 'prev') {
    setSearchParams((prev) => ({
      ...prev,
      first: null,
      last: limit,
      after: null,
      before: pageInfo?.startCursor,
    }));
  }
};

export const validateField = (_, value) => {
  if (value && _.field === 'email' && !validEmail.test(value)) {
    return Promise.reject(new Error('Please enter a valid email'));
  }
  if (!value && _.field === 'email') {
    return Promise.reject(new Error('This field is required!'));
  }
  if (!value && _.field === 'password') {
    return Promise.reject(new Error('This field is required!'));
  }

  return Promise.resolve();
};

export const getTagAttributes = (status) => {
  switch (status.toUpperCase()) {
    case 'ACTIVE':
      return {
        color: '#38A169',
        borderColor: '#38A169',
        backgroundColor: '#F0FFF4',
        title: 'ACTIVE',
      };

    case 'PENDING_APPROVAL':
      return {
        color: '#1A2035',
        backgroundColor: '#EDF2F7',
        title: 'PENDING APPROVAL',
      };
    case 'NEEDS_REVIEW':
      return {
        color: '#1A2035',
        backgroundColor: '#EDF2F7',
        title: 'NEEDS REVIEW',
      };
    case 'VERIFIED':
      return {
        color: '#38A169',
        backgroundColor: '#F0FFF4',
        title: 'VERIFIED',
      };

    case 'ARCHIVED':
      return {
        color: '#f50',
        backgroundColor: '',
        title: 'ARCHIVED',
      };
    case 'REJECTED':
      return {
        color: '#f50',
        backgroundColor: '',
        title: 'REJECTED',
      };
    default:
      return {
        color: '#1A2035',
        backgroundColor: '#EDF2F7',
        title: 'DRAFT',
      };
  }
};

export const validateWebsite = (_rule, value, callback) => {
  if (!value && _rule?.required) {
    callback('Website is required');
  } else if (
    value &&
    !/^[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/.test(
      value
    )
  ) {
    callback('Please enter a valid website URL e.g example.com');
  } else {
    callback();
  }
};
