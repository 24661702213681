import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const NewCustomers = Loadable({
  loader: () => import('./components/NewCustomer/NewCustomer'),
  loading: LazyLoading,
});

const EditCustomers = Loadable({
  loader: () => import('./components/NewCustomer/NewCustomer'),
  loading: LazyLoading,
});

const CustomerDetails = Loadable({
  loader: () => import('./components/CustomerDetails'),
  loading: LazyLoading,
});

const Customers = Loadable({
  loader: () => import('./components/Customers'),
  loading: LazyLoading,
});

export const routes = [
  {
    path: '/customers',
    component: Customers,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/customers/create',
    component: NewCustomers,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/customers/edit/:name',
    component: EditCustomers,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/customers/details/:name',
    component: CustomerDetails,
    props: {
      loggedInOnly: true,
    },
  },
];
