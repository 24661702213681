import React from 'react';
import PropTypes from 'prop-types';

const IconContainer = (props) => {
  const { icon, isAntdIcon } = props;
  return (
    <span
      style={{
        verticalAlign: isAntdIcon ? '' : 'sub',
        paddingRight: 5,
      }}
    >
      {icon}
    </span>
  );
};

IconContainer.propTypes = {
  icon: PropTypes.node,
  isAntdIcon: PropTypes.bool,
};

IconContainer.defaultProps = {
  isAntdIcon: false,
};

export default IconContainer;
