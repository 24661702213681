import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  Menu,
  Tag,
  Space,
  Button,
  Typography,
  notification,
  Modal,
} from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { navigationMenus } from './navigationMenus';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@/_contexts/AuthContext';
import { getFilteredMenusBasedOnMarketStatus } from '@/_helpers/navigation';
import UserDisplayNav from './UserDisplayNavContent';
import { Scrollbars } from 'react-custom-scrollbars';

import { getAppendedUrl } from '@/_helpers/helpers';
import {
  getMarketStatusVariables,
  getMakerProfileVariables,
} from '@/_helpers/market';

import { getCashAdvanceVariables } from '@/_helpers/finance';
import PBPLogo from '@/_assets/svg/pbp-logo.svg';
import PBPPassportLogo from '@/_assets/svg/PBP-Passport.svg';
import { logout } from '@/_helpers/auth';
import { handleErrorMessage } from '@/_helpers/helpers';
import { useUpdateMakerAccountMutation } from '@/generated/graphql';

//TODO: Fetch from Graphql

const NavigationSider = (props: any) => {
  const { t } = useTranslation();

  const defaultKey = useMemo(() => {
    return getAppendedUrl(props, true);
  }, [props]);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [openKeys, setOpenKeys] = useState([]);
  const [termsOpen, setTermsOpen] = useState(false);
  const {
    setUser: setUserContext,
    setCurrentCompany: setCurrentCompanyContext,
    user,
    currentCompany,
  } = useContext(AuthContext);

  useEffect(() => {
    if (user?.maker?.confirmMakerTerms === false) {
      setTermsOpen(true);
    }
  }, [user?.maker?.confirmMakerTerms]);

  const [updateMakerAccount, { loading: isMakerAccountUpdating }] =
    useUpdateMakerAccountMutation();

  const onTermsAccept = async () => {
    try {
      const res = await updateMakerAccount({
        variables: {
          input: { confirmMakerTerms: true },
          userId: user?.id,
        },
      });

      if (res?.data?.updateMakerAccount?.accountErrors?.length) {
        handleErrorMessage(res.data?.updateMakerAccount?.accountErrors, t);
        logout();
      } else {
        const createdUser = { ...res.data?.updateMakerAccount?.user };
        const maker = { ...res.data?.updateMakerAccount?.user?.maker };
        setUserContext(createdUser);
        setCurrentCompanyContext(maker);
        notification.success({
          message: t('Terms Accepted Successfully'),
          placement: 'topRight',
        });
      }
    } catch (error) {
      notification.error({
        message: t('Unexpected error occurred!'),
        placement: 'topRight',
      });
      logout();
    }
    setTermsOpen(false);
  };

  const { isTransform } = currentCompany;

  const menuRef = useRef(null);
  const isMounted = useRef(false);

  const getFilteredMenus = useCallback(
    (menus: any) => {
      return getFilteredMenusBasedOnMarketStatus(
        menus,
        currentCompany?.publishedStatus,
        currentCompany.isTransform
      );
    },
    [currentCompany?.publishedStatus, currentCompany.isTransform]
  );

  const handleMenus = useCallback(() => {
    let menus = [...navigationMenus];

    menus = getFilteredMenus(menus);

    setSelectedMenus(menus as any);
    setOpenKeys(getSubMenuKeys(menus as any) as any);

    setActiveKey(defaultKey);
  }, [defaultKey, getFilteredMenus]);

  useEffect(() => {
    if (isMounted.current === false) {
      handleMenus();
    }
    return () => {
      isMounted.current = true;
    };
  }, [
    defaultKey,
    handleMenus,
    user?.email,
    currentCompany?.name,
    currentCompany?.country,
  ]);

  const getSubMenuKeys = (menus = []) => {
    const subMenuKeys: any = [];
    menus.forEach((currentMenu: any) => {
      if (currentMenu?.items) {
        subMenuKeys.push(currentMenu.key);
      }
    });
    return subMenuKeys;
  };

  const handleActiveAndOpenKey = (parentKey: any, activeKey: any) => {
    setActiveKey(activeKey);
    const isOpen = !!openKeys.find((key) => key === parentKey);
    if (!isOpen) {
      setOpenKeys([...navigationMenus, parentKey] as any);
    }
  };

  //store the payout clicked in localstorage
  const handlePayoutClicked = () => {
    localStorage.setItem('isPayoutsClicked', 'true');
  };

  const getMenuExtra = useCallback(() => {
    const marketVariables = getMarketStatusVariables(
      t,
      currentCompany?.publishedStatus
    );
    const makerProfileVariable = getMakerProfileVariables(
      t,
      currentCompany?.publishedStatus,
      // @ts-ignore
      26
    );

    const cashAdvanceVariables = getCashAdvanceVariables(
      t,
      currentCompany?.cash_advance_status
    );

    const isPayoutsClicked = localStorage.getItem('isPayoutsClicked');
    const { isTransform } = currentCompany;

    const sideMenuElements = {
      Market: (
        <NavigationStatusTag color={marketVariables.color}>
          {marketVariables.text || 'Draft'}
        </NavigationStatusTag>
      ),
      CashAdvance: (
        <NavigationStatusTag color={cashAdvanceVariables.color}>
          {cashAdvanceVariables.label}
        </NavigationStatusTag>
      ),

      MakerProfile: (
        <Tag
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'middle',
            alignItems: 'center',
          }}
          color={makerProfileVariable.color}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: '0.75rem',
            }}
          >
            {makerProfileVariable.label || 'Draft'}
          </span>
        </Tag>
      ),
      Orders: currentCompany?.newOrders > 0 && (
        <Button
          type="default"
          shape="circle"
          size="small"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'middle',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '0.6rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'middle',
              alignItems: 'center',
            }}
          >
            {currentCompany?.newOrders > 10 ? '+10' : currentCompany?.newOrders}
          </span>
        </Button>
      ),
    };
    const payoutTag = (
      <Tag
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'middle',
          alignItems: 'center',
          borderRadius: '10px',
        }}
        color={'#F9F0FF'}
      >
        <span
          style={{
            fontWeight: 500,
            fontSize: '0.75rem',
            color: '#722ED1',
          }}
        >
          {t('New')}
        </span>
      </Tag>
    );

    return {
      ...sideMenuElements,
      ...(!isTransform && !isPayoutsClicked ? { Payouts: payoutTag } : {}),
    };
  }, [t, currentCompany]);

  const getMenuItemChild = useCallback(
    (menu: any) => (
      <Space
        style={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {menu?.externalLink ? (
          <>
            {menu.key === 'PaymentMethod' && (
              <span data-cy={menu.key}>{t(menu.title)}</span>
            )}
            {menu.key !== 'PaymentMethod' && (
              <a
                target="_blank"
                rel="noreferrer"
                href={menu.path}
                data-cy={menu.key}
              >
                {t(menu.title)}
              </a>
            )}
          </>
        ) : (
          <Link to={menu.path} data-cy={menu.key}>
            {t(menu.title)}
          </Link>
        )}
        {/* @ts-ignore */}
        {menu.showExtra ? getMenuExtra()[menu.key] : null}
      </Space>
    ),
    [getMenuExtra, t]
  );

  const handleChildMenus = (childMenu: any) => {
    setActiveKey(childMenu.path);
  };

  return (
    <>
      <StyledSider>
        <NavTop>
          <StyledSiderHeader>
            {!isTransform ? (
              <img data-testid="pbp-logo" src={PBPLogo} alt="PBP Logo" />
            ) : (
              <img
                data-testid="passport-logo"
                src={PBPPassportLogo}
                alt="PBP Passport Logo"
              />
            )}
          </StyledSiderHeader>
          <MenuSection>
            <Scrollbars autoHide>
              {/* @ts-ignore */}
              <StyledMenu
                mode="inline"
                style={{
                  background: '#f1f1f1',
                  width: 290,
                }}
                defaultSelectedKeys={[defaultKey]}
                ref={menuRef}
                selectedKeys={[activeKey]}
                openKeys={openKeys}
                onOpenChange={setOpenKeys}
              >
                {selectedMenus.map((menu: any) => {
                  return menu?.items ? (
                    <SubMenu
                      key={menu.key}
                      title={t(menu.title)}
                      icon={menu?.icon}
                    >
                      {menu.items.map((childMenu: any) => (
                        <Menu.Item
                          key={childMenu.path}
                          icon={childMenu?.icon}
                          onClick={() => handleChildMenus(childMenu)}
                        >
                          {getMenuItemChild(childMenu)}
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={menu.path}
                      icon={menu?.icon}
                      onClick={handlePayoutClicked}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignContent: 'middle',
                      }}
                    >
                      <span
                        style={{
                          verticalAlign: 'sub',
                          fontSize: '0.875rem',
                        }}
                      >
                        {getMenuItemChild(menu)}
                      </span>
                    </Menu.Item>
                  );
                })}
              </StyledMenu>
            </Scrollbars>
          </MenuSection>
        </NavTop>
        <NavBottom>
          <UserDisplayNav onViewProfileClick={handleActiveAndOpenKey} />
        </NavBottom>
      </StyledSider>
      <Modal
        closable={false}
        maskClosable={false}
        open={termsOpen}
        width={600}
        footer={null}
        maskStyle={{
          background: '#838383',
          opacity: 0.9,
        }}
        bodyStyle={{
          background: 'white',
        }}
      >
        <Typography.Text
          style={{
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {t('PBP’s Maker Terms have been updated')}
        </Typography.Text>
        <br />
        <br />
        <Typography.Text
          style={{
            fontSize: 14,
          }}
        >
          {t(
            'PBP has recently updated our Maker Terms. Please review the terms found at the link below and click accept to access your Maker Portal.'
          )}
        </Typography.Text>
        <br />
        <br />
        <Typography.Text
          style={{
            fontSize: 14,
          }}
        >
          {t(
            'If you have any questions, please don’t hesitate to contact makersupport@poweredbyepople.com'
          )}
        </Typography.Text>
        <br />
        <br />
        <a
          href="https://www.poweredbypeople.com/pages/maker-terms"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Typography.Text
            style={{
              cursor: 'pointer',
              fontSize: 14,
              textDecoration: 'underline',
            }}
          >
            {t('Access the Maker Terms here.')}
          </Typography.Text>
        </a>
        <br />
        <br />
        <Typography.Text
          style={{
            fontSize: 14,
          }}
        >
          {t(
            'You can find them at any time in the Resources section in the left navigation of the Maker Portal.'
          )}
        </Typography.Text>
        <br />
        <br />
        <br />
        <div
          style={{
            display: 'flex',
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={onTermsAccept}
            loading={isMakerAccountUpdating}
            type="primary"
            style={{
              fontSize: 14,
            }}
          >
            {t('Agree and Continue')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
const { SubMenu } = Menu;

const StyledSider = styled.div`
  height: 100% !important;
  width: 294px !important;
  background: #f1f1f1 !important;
  color: #737376;
  overflow: auto;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const NavTop = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const StyledSiderHeader = styled.div`
  height: 65px;
  padding: 11px 12px 11px 20px;
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
  font-size: 22px;
  line-height: 30px;
  color: #222;
  font-weight: 700;
  display: flex;
  .pbp-logo {
    border: 1px solid;
    border-radius: 5px;
    padding: 2px;
  }
`;

const MenuSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const StyledMenu = styled(Menu)`
  .ant-menu-submenu-title {
    color: #222;
    display: block;
    text-decoration: none;
    font-weight: 600;
    height: 30px !important;
    font-size: 14px !important;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    height: 35px !important;
    font-size: 14px !important;
    color: rgb(111 106 106);
  }
  .ant-menu-item a,
  .ant-menu-submenu-title {
    color: rgb(111 106 106);
    font-weight: 400;
  }
  .ant-menu-item a:hover {
    color: #222;
  }
  .ant-menu-sub.ant-menu-inline {
    background: #f1f1f1;
    font-size: 14px !important;
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    color: #222;
  }
  .ant-menu-item-selected .ant-menu-item a {
    color: #222;
  }
`;

const NavBottom = styled.div`
  border-top: 1px solid #d4d4d4;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  padding: 24px;
`;

const NavigationStatusTag = styled(Tag)`
  border-radius: 10px;
  font-weight: 500;
  margin: 0px;
`;
NavigationSider.propTypes = {};

export default NavigationSider;
