import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { history } from '@/_helpers/history';

const GoBackButton = (props) => {
  const { style, backURL } = props;
  const handleBack = () => {
    if (backURL) {
      history.push(backURL);
    } else {
      history.goBack();
    }
  };

  return (
    <Button
      type="ghost"
      onClick={handleBack}
      style={{
        marginRight: '5px',
        // borderRadius: 6,
        ...style,
      }}
      icon={<ArrowLeftOutlined size={20} />}
    ></Button>
  );
};

GoBackButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  backURL: PropTypes.string,
};

GoBackButton.defaultProps = {
  title: 'Back',
  style: {},
};

export default GoBackButton;
