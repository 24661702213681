import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

// const DueDiligence = Loadable({
//   loader: () => import('./components/DueDiligence'),
//   loading: LazyLoading,
// });

const DueDiligencePage = Loadable({
  loader: () => import('./components/DueDiligencePage'),
  loading: LazyLoading,
});

const DueDiligenceResult = Loadable({
  loader: () => import('./components/DueDiligenceResult'),
  loading: LazyLoading,
});

export const marketRegisterRoutes = [
  {
    path: '/market/due-diligence',
    component: DueDiligencePage,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/market/due-diligence/result',
    component: DueDiligenceResult,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];
