export const MixpanelActions = Object.freeze({
  ['Company Registration']: 'Company Registration',
  ['Filled Due Diligence']: 'Filled Due Diligence',
  ['Added a Product']: 'Added a Product',
  ['Added a Customer']: 'Added a Customer',
  ['Created a Sales Order']: 'Created a Sales Order',
  ['Viewed Homepage']: 'Viewed Homepage',
  ['Viewed Maker Profile Page']: 'Viewed Maker Profile Page',
  ['Viewed Apply to Market Page']: 'Viewed Apply to Market Page',
  ['Viewed Product List Page']: 'Viewed Product List Page',
  ['Viewed a Product']: 'Viewed a Product',
  ['Viewed a Variant']: 'Viewed a Variant',
  ['Viewed Customers Page']: 'Viewed Customers Page',
  ['Viewed a Customer']: 'Viewed a Customer',
  ['Viewed Orders']: 'Viewed Orders',
  ['Viewed an Order']: 'Viewed an Order',
  ['Viewed Payments Page']: 'Viewed Payments Page',
  ['Viewed Users Page']: 'Viewed Users Page',
  ['Viewed Profile Page']: 'Viewed Profile Page',
  ['Viewed Resources Page']: 'Viewed Resources Page',
  ['Viewed Shopify Integration Page']: 'Viewed Shopify Integration Page',
  ['Navigate to Homepage']: 'Navigate to Homepage',
  ['Navigate to Apply to Market']: 'Navigate to Apply to Market',
  ['Navigate to Products']: 'Navigate to Products',
  ['Navigate to Customers']: 'Navigate to Customers',
  ['Navigate to Orders']: 'Navigate to Orders',
  ['Navigate to Payments']: 'Navigate to Payments',
  ['Navigate to Cash Flow']: 'Navigate to Cash Flow',
  ['Navigate to Users']: 'Navigate to Users',
  ['Navigate to Profile']: 'Navigate to Profile',
  ['Navigate to Product Attributes']: 'Navigate to Product Attributes',
  ['Navigate to Resources']: 'Navigate to Resources',
  ['Navigate to Payment Methods']: 'Navigate to Payment Methods',
  ['Navigate to Shopify Integrations']: 'Navigate to Shopify Integrations',
  ['Sign in with Google']: 'Sign in with Google',
  [' Sign in with cognito']: 'Sign in with cognito',
  ['Sign up']: 'Sign up',
  ['Marked Order as Shipped']: 'Marked Order as Shipped',
  ['Decline Order']: 'Decline Order',
  ['Confirmed Order']: 'Confirmed Order',
  ['Updated a Variant']: 'Updated a Variant',
  ['Published Product to Market']: 'Published Product to Market',
  ['Updated a Product']: 'Updated a Product',
  ['Created a Product']: 'Created a Product',
  ['Edited Maker Profile']: 'Edited Maker Profile',
  ['Submitted Profile to Market']: 'Submitted Profile to Market',
  ['Previewed Online Store']: 'Previewed Online Store',
  ['Saved Due Diligence']: 'Saved Due Diligence',
  ['Started Due Diligence']: 'Started Due Diligence',
  ['Encountered error page']: 'Encountered error page',
});
