export const OperatorEnums = Object.freeze({
  LIKE: 'like', // use format for params %${name}%
  BETWEEN: 'Between', // params should be an array date(YYYY-MM-DD)
  IN: 'in', // params should be an array
  EQUALS: '=',
  NOT_EQUAL: '!=',
  NOT_LIKE: 'not like', // use format for params %${name}%
  NOT_IN: 'not in', // params should be an array
  IS: 'is', // params are set and "not set"
  GREATER_THAN: '>',
  GREATER_THAN_OR_EQUAL_TO: '>=',
  LESS_THAN: '<',
  LESS_THAN_OR_EQUAL_TO: '<=',
});
