import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '@/_contexts/AuthContext';
import Cookies from 'js-cookie';
import { AUTH } from '@/constants';
import { COMPANY_SETUP, HOME } from '@/_helpers/urls';

type RoutesWithSubRoutesProps = {
  component: React.FC<any>;
  path: string;
  exact?: boolean;
  name?: string;
  loggedInOnly?: boolean;
  loggedOutOnly?: boolean;
  [x: string]: any;
};

const RoutesWithSubRoutes: React.FC<RoutesWithSubRoutesProps> = (props) => {
  const {
    component: Component,
    loggedInOnly,
    loggedOutOnly,
    location,
    ...rest
  } = props;

  const { isAuthenticated, setIsAuthenticated, currentCompany } =
    useContext(AuthContext);
  const token = Cookies.get(AUTH.token);
  const getRedirectUrl = () => {
    let redirect = null;
    if (
      loggedOutOnly &&
      token &&
      isAuthenticated &&
      !currentCompany?.name &&
      location.pathname !== COMPANY_SETUP
    ) {
      redirect = COMPANY_SETUP;
    } else if (
      loggedOutOnly &&
      token &&
      isAuthenticated &&
      currentCompany?.name
    ) {
      redirect = HOME;
    } else if (
      loggedInOnly &&
      token &&
      isAuthenticated &&
      !currentCompany?.name
    ) {
      redirect = COMPANY_SETUP;
    } else if (loggedInOnly && !isAuthenticated) {
      localStorage.setItem('redirectURL', location.pathname);
      redirect = '/login';
    } else if (loggedOutOnly && isAuthenticated && !token) {
      setIsAuthenticated(false);
    }
    return redirect;
  };

  const handleRedirect = (pathname = '/', location: any) => {
    const isExternalLink = pathname.includes('https');
    if (isExternalLink) {
      window.location.href = pathname;
    } else {
      // @ts-ignore
      return <Redirect to={{ pathname, location }} />;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        const { location } = props;
        const redirect = getRedirectUrl();
        return redirect ? (
          handleRedirect(redirect, location)
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default RoutesWithSubRoutes;
