import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const MyProfile = Loadable({
  loader: () => import('./components/MyProfile'),
  loading: LazyLoading,
});

export const myProfileRoutes = [
  {
    path: '/settings/my-profile',
    exact: true,
    props: {
      loggedInOnly: true,
    },
    component: MyProfile,
  },
];
