export const themeTemplate = {
  // primaryColor: '#334bfa',
  primaryColor: '#000000',
  secondaryColor: '#e3e7fa',
  lightGrey: '#f8f8f8',
  themeColor: '#f6ece8',
  accentColor: '#fff87e',
  pbpDarkGrey: '#575757',
};

export const statusColors = {
  default: 'cyan',
  warning: 'orange',
  danger: 'red',
  success: 'green',
};
