import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const CreateSalesOrder = Loadable({
  loader: () => import('./components/NewSalesOrder'),
  loading: LazyLoading,
});

const SalesOrderDetails = Loadable({
  loader: () => import('./components/SalesOrderDetails'),
  loading: LazyLoading,
});

const SalesOrders = Loadable({
  loader: () => import('./components/SalesOrders'),
  loading: LazyLoading,
});

const SalesOrderList = Loadable({
  loader: () => import('./components/SalesOrderList'),
  loading: LazyLoading,
});

const CreateOrder = Loadable({
  loader: () => import('./components/CreateOrder'),
  loading: LazyLoading,
});

const OrderDetails = Loadable({
  loader: () => import('./components/OrderDetails'),
  loading: LazyLoading,
});

const EditOrder = Loadable({
  loader: () => import('./components/EditOrder'),
  loading: LazyLoading,
});

const SalesOrderFulfillment = Loadable({
  loader: () => import('./components/OrderFulfillment'),
  loading: LazyLoading,
});

const ShipmentInformationDetails = Loadable({
  loader: () => import('./components/ShipmentInformationDetails'),
  loading: LazyLoading,
});

export const salesRoutes = [
  // TODO: This will be deactivated once we switch the table layout
  {
    path: '/orders',
    component: SalesOrderList,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/orders/create',
    component: CreateOrder,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/orders/:id',
    component: OrderDetails,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/orders/:id/edit',
    component: EditOrder,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },

  {
    path: '/orders/sales-orders',
    component: SalesOrders,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/orders/sales-orders/create',
    component: CreateSalesOrder,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },

  {
    path: '/orders/sales-order/:id/details',
    component: SalesOrderDetails,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/orders/sales-order/:id/fulfill',
    component: SalesOrderFulfillment,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/orders/sales-order/:id/shipment-information',
    component: ShipmentInformationDetails,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];
