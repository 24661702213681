import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './i18n';
import { getAreaSort } from './_helpers/helpers';
import App from './App';
import './index.css';
import './fonts/TradeGothicNextLTPro-Rg.otf';
import { client } from './graphql/config';
import ErrorBoundaryFallback from './_components/ErrorBoundaryFallback';

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Could not find root element');
}
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Sentry.ErrorBoundary
    fallback={({ error }) => <ErrorBoundaryFallback error={error} />}
  >
    <ConfigProvider locale={en} areaSorter={getAreaSort}>
      <ApolloProvider client={client}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
        >
          <App />
        </GoogleOAuthProvider>
      </ApolloProvider>
    </ConfigProvider>
  </Sentry.ErrorBoundary>
);
