import { Layout } from 'antd';
import styled from 'styled-components';
import React, { useRef } from 'react';
import { Switch } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Scrollbars } from 'react-custom-scrollbars';

import { authenticatedRoutes } from '@/routes';
import NewRoutesWithSubRoutes from '@/_templates/RoutesWithSubRoutes';
import NavigationSider from './Sider/NavigationSider';
import { GlobalFormStyles } from '@/_components/StyledComponents';
import { useMediaQuery } from 'react-responsive';

type NavigationLayoutProps = {
  [x: string]: any;
};

const NavigationLayout: React.FC<NavigationLayoutProps> = (props) => {
  const scrollRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const scrollToTop = () => {
    if (scrollRef.current) {
      // @ts-ignore
      scrollRef.current.view.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Layout style={{ height: '100%' }}>
      <NavigationSiderContainer>
        <NavigationSider {...props} />
      </NavigationSiderContainer>
      <StyledContent>
        <Scrollbars ref={scrollRef}>
          <Switch>
            {authenticatedRoutes.map((route, idx) => {
              return route.component ? (
                <NewRoutesWithSubRoutes
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  // @ts-ignore
                  name={route.name}
                  component={(routeProps: any) => (
                    <route.component
                      scrollToTop={scrollToTop}
                      isMobile={isMobile}
                      {...routeProps}
                    />
                  )}
                  loggedInOnly={route.props.loggedInOnly}
                />
              ) : null;
            })}
          </Switch>
        </Scrollbars>
      </StyledContent>
      <Toaster position="bottom-center" reverseOrder />
    </Layout>
  );
};

const StyledContent = styled.div`
  border-left: 1px solid #d4d4d4;
  background: #f1f1f1;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: absolute;
  left: 294px;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    left: 0px !important;
  }
  .ant-form-item {
    ${GlobalFormStyles}
  }
`;

const NavigationSiderContainer = styled.div`
  display: flex;
  height: 100%;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;
export default NavigationLayout;
